import { TireConstants } from "./inventory.models";

export const TireBrands: TireConstants["tireBrands"] = {
  0: "Accis",
  1: "Acelere",
  2: "Advan",
  3: "Aeolus",
  4: "Alliance",
  5: "Ambassador",
  6: "Americus",
  7: "Antares",
  8: "Apollo",
  9: "Arctic Claw",
  10: "Armstrong",
  11: "Arrowspeed",
  12: "Atlas",
  13: "Aurora",
  14: "Austone",
  15: "Avon",
  16: "BFGoodrich",
  17: "Beijing Capital Tire",
  18: "Bridgestone",
  19: "Camso",
  20: "Capitol",
  21: "Continental",
  22: "Cooper",
  23: "Cordovan",
  24: "Crosswind",
  25: "Dayton",
  26: "Dean",
  27: "Delinte",
  28: "Delta",
  29: "Dick Cepek",
  30: "Douglas",
  31: "Dunlop",
  32: "Duraturn",
  33: "Falken",
  34: "Federal",
  35: "Firestone",
  36: "Fuzion",
  37: "GT Radial",
  38: "General",
  39: "Generic",
  40: "GoForm",
  41: "Good ride",
  42: "GoodRide",
  43: "Goodyear",
  44: "Grand Prix",
  45: "Greenmax",
  46: "Hallmark Republic",
  47: "Hankook",
  48: "Hercules",
  49: "Hi-Fly",
  50: "Kelly",
  51: "Kenda",
  52: "Kingstar",
  53: "Kumho",
  54: "Landvigator",
  55: "Ling Long",
  56: "Marshal",
  57: "Mastercraft",
  58: "Merit",
  59: "Michelin",
  60: "Mickey Thompson",
  61: "Milestar",
  62: "Mirada",
  63: "Motomaster",
  64: "Multi-Mile",
  65: "Nankang",
  66: "Nexen",
  67: "Nitto",
  68: "Nokian Tyres",
  69: "OHTSU Tires",
  70: "Pirelli",
  71: "Power King",
  72: "Primewell",
  73: "Remington",
  74: "Riken",
  75: "Road King",
  76: "Road handler",
  77: "Roadhandler",
  78: "Sailun",
  79: "Saxon",
  80: "Semperit",
  81: "Sigma",
  82: "Solideal",
  83: "Sonar",
  84: "Spector",
  85: "Starfire",
  86: "Steel Radial",
  87: "Sumitomo",
  88: "Summit",
  89: "Telstar",
  90: "Tempra",
  91: "Thunderer",
  92: "Touring Radial",
  93: "Toyo",
  94: "Trail Handler",
  95: "TrailHandler",
  96: "Tristar",
  97: "Ulysses ST",
  98: "Uniroyal",
  99: "Vantage",
  100: "Venezia",
  101: "Vogue",
  102: "Wanli",
  103: "Wild Spirit",
  104: "Wild Trail",
  105: "Winda",
  106: "Yokohama",
  107: "Zeetex",
};

export const TireSpeedRatings: TireConstants["tireSpeedRatings"] = {
  0: { value: "L-120km/h", text: "L - 120 km/h - 75 mph" },
  1: { value: "M-130km/h", text: "M - 130km/h - 81 mph" },
  2: { value: "N-140km/h", text: "N - 140km/h - 87 mph" },
  3: { value: "P-150km/h", text: "P - 150km/h - 93 mph" },
  4: { value: "Q-160km/h", text: "Q - 160km/h - 99 mph" },
  5: { value: "R-170km/h", text: "R - 170km/h - 106 mph" },
  6: { value: "S-180km/h", text: "S - 180km/h - 112 mph" },
  7: { value: "T-190km/h", text: "T - 190km/h - 118 mph" },
  8: { value: "U-200km/h", text: "U - 200km/h - 124 mph" },
  9: { value: "H-210km/h", text: "H - 210km/h - 130 mph" },
  10: { value: "V-240km/h", text: "V - 240km/h - 149 mph" },
  11: { value: "W-270km/h", text: "W - 270km/h - 168 mph" },
  12: { value: "Y-300km/h", text: "Y - 300km/h - 186 mph" },
};

export const TireSeasonRatings: TireConstants["tireSeasonRatings"] = {
  0: "All Weather",
  1: "All season",
  2: "All-terrain",
  3: "Heavy duty truck",
  4: "High performance",
  5: "Mud and snow",
  6: "Racing",
  7: "Rain",
  8: "Run-flat",
  9: "Spare",
  10: "Summer",
  11: "Winter",
};

export const TirePly: TireConstants["tirePly"] = {
  0: "4",
  1: "6",
  2: "8",
  3: "10",
  4: "12",
};

export const TireSizes: TireConstants["tireSizes"] = {
  0: { width: "9.5", aspect: "0", diameter: "16.5" }, // no ratio for this size! (As Italo says)
  1: { width: "30", aspect: "9.5", diameter: "15" },
  2: { width: "31", aspect: "10.5", diameter: "15" },
  3: { width: "32", aspect: "11.5", diameter: "15" },
  4: { width: "33", aspect: "9.5", diameter: "15" },
  5: { width: "33", aspect: "10.5", diameter: "15" },
  6: { width: "33", aspect: "12.5", diameter: "15" },
  7: { width: "33", aspect: "12.5", diameter: "16.5" },
  8: { width: "33", aspect: "12.5", diameter: "20" },
  9: { width: "34", aspect: "10.5", diameter: "17" },
  10: { width: "34", aspect: "12.5", diameter: "18" },
  11: { width: "35", aspect: "12.5", diameter: "15" },
  12: { width: "35", aspect: "12.5", diameter: "16.5" },
  13: { width: "35", aspect: "12.5", diameter: "17" },
  14: { width: "35", aspect: "12.5", diameter: "18" },
  15: { width: "35", aspect: "12.5", diameter: "20" },
  16: { width: "35", aspect: "13.5", diameter: "15" },
  17: { width: "36", aspect: "13.5", diameter: "18" },
  18: { width: "37", aspect: "12.5", diameter: "17" },
  19: { width: "37", aspect: "12.5", diameter: "18" },
  20: { width: "37", aspect: "12.5", diameter: "20" },
  21: { width: "38", aspect: "14.5", diameter: "17" },
  22: { width: "38", aspect: "14.5", diameter: "18" },
  23: { width: "38", aspect: "14.5", diameter: "20" },
  24: { width: "39", aspect: "13.5", diameter: "17" },
  25: { width: "40", aspect: "14.5", diameter: "17" },
  26: { width: "40", aspect: "14.5", diameter: "18" },
  27: { width: "40", aspect: "14.5", diameter: "20" },
  28: { width: "42", aspect: "14.5", diameter: "20" },
  29: { width: "105", aspect: "70", diameter: "14" },
  30: { width: "105", aspect: "70", diameter: "15" },
  31: { width: "115", aspect: "70", diameter: "14" },
  32: { width: "115", aspect: "70", diameter: "15" },
  33: { width: "125", aspect: "60", diameter: "18" },
  34: { width: "125", aspect: "70", diameter: "14" },
  35: { width: "125", aspect: "70", diameter: "15" },
  36: { width: "125", aspect: "70", diameter: "16" },
  37: { width: "125", aspect: "70", diameter: "17" },
  38: { width: "125", aspect: "70", diameter: "18" },
  39: { width: "125", aspect: "70", diameter: "19" },
  40: { width: "125", aspect: "80", diameter: "15" },
  41: { width: "125", aspect: "80", diameter: "16" },
  42: { width: "125", aspect: "80", diameter: "17" },
  43: { width: "125", aspect: "90", diameter: "16" },
  44: { width: "135", aspect: "70", diameter: "16" },
  45: { width: "135", aspect: "70", diameter: "17" },
  46: { width: "135", aspect: "70", diameter: "19" },
  47: { width: "135", aspect: "80", diameter: "15" },
  48: { width: "135", aspect: "80", diameter: "16" },
  49: { width: "135", aspect: "80", diameter: "17" },
  50: { width: "135", aspect: "80", diameter: "18" },
  51: { width: "135", aspect: "90", diameter: "15" },
  52: { width: "135", aspect: "90", diameter: "16" },
  53: { width: "135", aspect: "90", diameter: "17" },
  54: { width: "145", aspect: "60", diameter: "20" },
  55: { width: "145", aspect: "65", diameter: "15" },
  56: { width: "145", aspect: "70", diameter: "17" },
  57: { width: "145", aspect: "70", diameter: "18" },
  58: { width: "145", aspect: "80", diameter: "16" },
  59: { width: "145", aspect: "80", diameter: "17" },
  60: { width: "145", aspect: "80", diameter: "18" },
  61: { width: "145", aspect: "80", diameter: "19" },
  62: { width: "145", aspect: "90", diameter: "16" },
  63: { width: "155", aspect: "60", diameter: "15" },
  64: { width: "155", aspect: "60", diameter: "18" },
  65: { width: "155", aspect: "60", diameter: "20" },
  66: { width: "155", aspect: "65", diameter: "20" },
  67: { width: "155", aspect: "70", diameter: "17" },
  68: { width: "155", aspect: "70", diameter: "19" },
  69: { width: "155", aspect: "80", diameter: "12" },
  70: { width: "155", aspect: "80", diameter: "13" },
  71: { width: "155", aspect: "80", diameter: "15" },
  72: { width: "155", aspect: "80", diameter: "17" },
  73: { width: "155", aspect: "80", diameter: "19" },
  74: { width: "155", aspect: "85", diameter: "18" },
  75: { width: "155", aspect: "90", diameter: "16" },
  76: { width: "155", aspect: "90", diameter: "17" },
  77: { width: "165", aspect: "60", diameter: "15" },
  78: { width: "165", aspect: "60", diameter: "20" },
  79: { width: "165", aspect: "65", diameter: "13" },
  80: { width: "165", aspect: "65", diameter: "14" },
  81: { width: "165", aspect: "65", diameter: "15" },
  82: { width: "165", aspect: "70", diameter: "10" },
  83: { width: "165", aspect: "80", diameter: "15" },
  84: { width: "165", aspect: "80", diameter: "17" },
  85: { width: "165", aspect: "90", diameter: "17" },
  86: { width: "165", aspect: "90", diameter: "18" },
  87: { width: "165", aspect: "90", diameter: "19" },
  88: { width: "175", aspect: "50", diameter: "16" },
  89: { width: "175", aspect: "55", diameter: "15" },
  90: { width: "175", aspect: "55", diameter: "16" },
  91: { width: "175", aspect: "55", diameter: "20" },
  92: { width: "175", aspect: "60", diameter: "19" },
  93: { width: "175", aspect: "65", diameter: "14" },
  94: { width: "175", aspect: "65", diameter: "15" },
  95: { width: "175", aspect: "70", diameter: "13" },
  96: { width: "175", aspect: "70", diameter: "14" },
  97: { width: "175", aspect: "70", diameter: "15" },
  98: { width: "175", aspect: "80", diameter: "13" },
  99: { width: "175", aspect: "80", diameter: "19" },
  100: { width: "175", aspect: "90", diameter: "17" },
  101: { width: "175", aspect: "90", diameter: "18" },
  102: { width: "185", aspect: "50", diameter: "16" },
  103: { width: "185", aspect: "55", diameter: "14" },
  104: { width: "185", aspect: "55", diameter: "15" },
  105: { width: "185", aspect: "55", diameter: "16" },
  106: { width: "185", aspect: "60", diameter: "13" },
  107: { width: "185", aspect: "60", diameter: "14" },
  108: { width: "185", aspect: "60", diameter: "15" },
  109: { width: "185", aspect: "60", diameter: "16" },
  110: { width: "185", aspect: "65", diameter: "14" },
  111: { width: "185", aspect: "65", diameter: "15" },
  112: { width: "185", aspect: "70", diameter: "13" },
  113: { width: "185", aspect: "70", diameter: "14" },
  114: { width: "185", aspect: "70", diameter: "15" },
  115: { width: "185", aspect: "75", diameter: "14" },
  116: { width: "185", aspect: "80", diameter: "13" },
  117: { width: "195", aspect: "40", diameter: "16" },
  118: { width: "195", aspect: "40", diameter: "17" },
  119: { width: "195", aspect: "45", diameter: "15" },
  120: { width: "195", aspect: "45", diameter: "16" },
  121: { width: "195", aspect: "45", diameter: "17" },
  122: { width: "195", aspect: "50", diameter: "15" },
  123: { width: "195", aspect: "50", diameter: "16" },
  124: { width: "195", aspect: "50", diameter: "20" },
  125: { width: "195", aspect: "55", diameter: "15" },
  126: { width: "195", aspect: "55", diameter: "16" },
  127: { width: "195", aspect: "60", diameter: "14" },
  128: { width: "195", aspect: "60", diameter: "15" },
  129: { width: "195", aspect: "60", diameter: "16" },
  130: { width: "195", aspect: "65", diameter: "14" },
  131: { width: "195", aspect: "65", diameter: "15" },
  132: { width: "195", aspect: "70", diameter: "14" },
  133: { width: "195", aspect: "70", diameter: "15" },
  134: { width: "195", aspect: "75", diameter: "14" },
  135: { width: "195", aspect: "75", diameter: "16" },
  136: { width: "195", aspect: "80", diameter: "16" },
  137: { width: "205", aspect: "40", diameter: "16" },
  138: { width: "205", aspect: "40", diameter: "17" },
  139: { width: "205", aspect: "40", diameter: "18" },
  140: { width: "205", aspect: "45", diameter: "16" },
  141: { width: "205", aspect: "45", diameter: "17" },
  142: { width: "205", aspect: "50", diameter: "14" },
  143: { width: "205", aspect: "50", diameter: "15" },
  144: { width: "205", aspect: "50", diameter: "16" },
  145: { width: "205", aspect: "50", diameter: "17" },
  146: { width: "205", aspect: "55", diameter: "14" },
  147: { width: "205", aspect: "55", diameter: "15" },
  148: { width: "205", aspect: "55", diameter: "16" },
  149: { width: "205", aspect: "55", diameter: "17" },
  150: { width: "205", aspect: "60", diameter: "13" },
  151: { width: "205", aspect: "60", diameter: "15" },
  152: { width: "205", aspect: "60", diameter: "16" },
  153: { width: "205", aspect: "65", diameter: "15" },
  154: { width: "205", aspect: "65", diameter: "16" },
  155: { width: "205", aspect: "70", diameter: "14" },
  156: { width: "205", aspect: "70", diameter: "15" },
  157: { width: "205", aspect: "70", diameter: "16" },
  158: { width: "205", aspect: "75", diameter: "14" },
  159: { width: "205", aspect: "75", diameter: "15" },
  160: { width: "205", aspect: "75", diameter: "16" },
  161: { width: "215", aspect: "35", diameter: "16" },
  162: { width: "215", aspect: "35", diameter: "18" },
  163: { width: "215", aspect: "35", diameter: "19" },
  164: { width: "215", aspect: "40", diameter: "16" },
  165: { width: "215", aspect: "40", diameter: "17" },
  166: { width: "215", aspect: "40", diameter: "18" },
  167: { width: "215", aspect: "45", diameter: "16" },
  168: { width: "215", aspect: "45", diameter: "17" },
  169: { width: "215", aspect: "45", diameter: "18" },
  170: { width: "215", aspect: "45", diameter: "20" },
  171: { width: "215", aspect: "50", diameter: "15" },
  172: { width: "215", aspect: "50", diameter: "16" },
  173: { width: "215", aspect: "50", diameter: "17" },
  174: { width: "215", aspect: "50", diameter: "18" },
  175: { width: "215", aspect: "55", diameter: "16" },
  176: { width: "215", aspect: "55", diameter: "17" },
  177: { width: "215", aspect: "55", diameter: "18" },
  178: { width: "215", aspect: "60", diameter: "14" },
  179: { width: "215", aspect: "60", diameter: "15" },
  180: { width: "215", aspect: "60", diameter: "16" },
  181: { width: "215", aspect: "60", diameter: "17" },
  182: { width: "215", aspect: "65", diameter: "15" },
  183: { width: "215", aspect: "65", diameter: "16" },
  184: { width: "215", aspect: "65", diameter: "17" },
  185: { width: "215", aspect: "70", diameter: "14" },
  186: { width: "215", aspect: "70", diameter: "15" },
  187: { width: "215", aspect: "70", diameter: "16" },
  188: { width: "215", aspect: "70", diameter: "17" },
  189: { width: "215", aspect: "75", diameter: "14" },
  190: { width: "215", aspect: "75", diameter: "15" },
  191: { width: "215", aspect: "75", diameter: "16" },
  192: { width: "215", aspect: "75", diameter: "17.5" },
  193: { width: "215", aspect: "85", diameter: "16" },
  194: { width: "225", aspect: "30", diameter: "20" },
  195: { width: "225", aspect: "35", diameter: "17" },
  196: { width: "225", aspect: "35", diameter: "18" },
  197: { width: "225", aspect: "35", diameter: "19" },
  198: { width: "225", aspect: "35", diameter: "20" },
  199: { width: "225", aspect: "40", diameter: "13" },
  200: { width: "225", aspect: "40", diameter: "17" },
  201: { width: "225", aspect: "40", diameter: "18" },
  202: { width: "225", aspect: "40", diameter: "19" },
  203: { width: "225", aspect: "45", diameter: "13" },
  204: { width: "225", aspect: "45", diameter: "15" },
  205: { width: "225", aspect: "45", diameter: "16" },
  206: { width: "225", aspect: "45", diameter: "17" },
  207: { width: "225", aspect: "45", diameter: "18" },
  208: { width: "225", aspect: "45", diameter: "19" },
  209: { width: "225", aspect: "50", diameter: "13" },
  210: { width: "225", aspect: "50", diameter: "14" },
  211: { width: "225", aspect: "50", diameter: "15" },
  212: { width: "225", aspect: "50", diameter: "16" },
  213: { width: "225", aspect: "50", diameter: "17" },
  214: { width: "225", aspect: "50", diameter: "18" },
  215: { width: "225", aspect: "55", diameter: "16" },
  216: { width: "225", aspect: "55", diameter: "17" },
  217: { width: "225", aspect: "55", diameter: "18" },
  218: { width: "225", aspect: "55", diameter: "19" },
  219: { width: "225", aspect: "60", diameter: "14" },
  220: { width: "225", aspect: "60", diameter: "15" },
  221: { width: "225", aspect: "60", diameter: "16" },
  222: { width: "225", aspect: "60", diameter: "17" },
  223: { width: "225", aspect: "60", diameter: "18" },
  224: { width: "225", aspect: "65", diameter: "16" },
  225: { width: "225", aspect: "65", diameter: "17" },
  226: { width: "225", aspect: "70", diameter: "14" },
  227: { width: "225", aspect: "70", diameter: "15" },
  228: { width: "225", aspect: "70", diameter: "16" },
  229: { width: "225", aspect: "70", diameter: "17" },
  230: { width: "225", aspect: "70", diameter: "19.5" },
  231: { width: "225", aspect: "75", diameter: "15" },
  232: { width: "225", aspect: "75", diameter: "16" },
  233: { width: "225", aspect: "75", diameter: "17" },
  234: { width: "235", aspect: "30", diameter: "19" },
  235: { width: "235", aspect: "30", diameter: "20" },
  236: { width: "235", aspect: "30", diameter: "22" },
  237: { width: "235", aspect: "35", diameter: "18" },
  238: { width: "235", aspect: "35", diameter: "19" },
  239: { width: "235", aspect: "35", diameter: "20" },
  240: { width: "235", aspect: "40", diameter: "17" },
  241: { width: "235", aspect: "40", diameter: "18" },
  242: { width: "235", aspect: "40", diameter: "19" },
  243: { width: "235", aspect: "40", diameter: "20" },
  244: { width: "235", aspect: "45", diameter: "17" },
  245: { width: "235", aspect: "45", diameter: "18" },
  246: { width: "235", aspect: "45", diameter: "19" },
  247: { width: "235", aspect: "45", diameter: "20" },
  248: { width: "235", aspect: "50", diameter: "15" },
  249: { width: "235", aspect: "50", diameter: "17" },
  250: { width: "235", aspect: "50", diameter: "18" },
  251: { width: "235", aspect: "50", diameter: "19" },
  252: { width: "235", aspect: "55", diameter: "16" },
  253: { width: "235", aspect: "55", diameter: "17" },
  254: { width: "235", aspect: "55", diameter: "18" },
  255: { width: "235", aspect: "55", diameter: "19" },
  256: { width: "235", aspect: "55", diameter: "20" },
  257: { width: "235", aspect: "60", diameter: "14" },
  258: { width: "235", aspect: "60", diameter: "15" },
  259: { width: "235", aspect: "60", diameter: "16" },
  260: { width: "235", aspect: "60", diameter: "17" },
  261: { width: "235", aspect: "60", diameter: "18" },
  262: { width: "235", aspect: "65", diameter: "16" },
  263: { width: "235", aspect: "65", diameter: "17" },
  264: { width: "235", aspect: "65", diameter: "18" },
  265: { width: "235", aspect: "65", diameter: "19" },
  266: { width: "235", aspect: "70", diameter: "15" },
  267: { width: "235", aspect: "70", diameter: "16" },
  268: { width: "235", aspect: "70", diameter: "17" },
  269: { width: "235", aspect: "75", diameter: "15" },
  270: { width: "235", aspect: "75", diameter: "16" },
  271: { width: "235", aspect: "75", diameter: "17" },
  272: { width: "235", aspect: "80", diameter: "16" },
  273: { width: "235", aspect: "80", diameter: "17" },
  274: { width: "235", aspect: "85", diameter: "16" },
  275: { width: "235", aspect: "85", diameter: "17" },
  276: { width: "245", aspect: "30", diameter: "19" },
  277: { width: "245", aspect: "30", diameter: "20" },
  278: { width: "245", aspect: "30", diameter: "21" },
  279: { width: "245", aspect: "35", diameter: "17" },
  280: { width: "245", aspect: "35", diameter: "18" },
  281: { width: "245", aspect: "35", diameter: "19" },
  282: { width: "245", aspect: "35", diameter: "20" },
  283: { width: "245", aspect: "35", diameter: "21" },
  284: { width: "245", aspect: "40", diameter: "15" },
  285: { width: "245", aspect: "40", diameter: "17" },
  286: { width: "245", aspect: "40", diameter: "18" },
  287: { width: "245", aspect: "40", diameter: "19" },
  288: { width: "245", aspect: "40", diameter: "20" },
  289: { width: "245", aspect: "40", diameter: "21" },
  290: { width: "245", aspect: "45", diameter: "16" },
  291: { width: "245", aspect: "45", diameter: "17" },
  292: { width: "245", aspect: "45", diameter: "18" },
  293: { width: "245", aspect: "45", diameter: "19" },
  294: { width: "245", aspect: "45", diameter: "20" },
  295: { width: "245", aspect: "50", diameter: "15" },
  296: { width: "245", aspect: "50", diameter: "16" },
  297: { width: "245", aspect: "50", diameter: "17" },
  298: { width: "245", aspect: "50", diameter: "18" },
  299: { width: "245", aspect: "50", diameter: "19" },
  300: { width: "245", aspect: "50", diameter: "20" },
  301: { width: "245", aspect: "55", diameter: "17" },
  302: { width: "245", aspect: "55", diameter: "18" },
  303: { width: "245", aspect: "55", diameter: "19" },
  304: { width: "245", aspect: "60", diameter: "14" },
  305: { width: "245", aspect: "60", diameter: "15" },
  306: { width: "245", aspect: "60", diameter: "17" },
  307: { width: "245", aspect: "60", diameter: "18" },
  308: { width: "245", aspect: "60", diameter: "20" },
  309: { width: "245", aspect: "65", diameter: "17" },
  310: { width: "245", aspect: "65", diameter: "18" },
  311: { width: "245", aspect: "70", diameter: "16" },
  312: { width: "245", aspect: "70", diameter: "17" },
  313: { width: "245", aspect: "70", diameter: "19.5" },
  314: { width: "245", aspect: "75", diameter: "16" },
  315: { width: "245", aspect: "75", diameter: "17" },
  316: { width: "255", aspect: "30", diameter: "19" },
  317: { width: "255", aspect: "30", diameter: "20" },
  318: { width: "255", aspect: "30", diameter: "21" },
  319: { width: "255", aspect: "30", diameter: "22" },
  320: { width: "255", aspect: "30", diameter: "24" },
  321: { width: "255", aspect: "35", diameter: "18" },
  322: { width: "255", aspect: "35", diameter: "19" },
  323: { width: "255", aspect: "35", diameter: "20" },
  324: { width: "255", aspect: "35", diameter: "21" },
  325: { width: "255", aspect: "35", diameter: "22" },
  326: { width: "255", aspect: "40", diameter: "17" },
  327: { width: "255", aspect: "40", diameter: "18" },
  328: { width: "255", aspect: "40", diameter: "19" },
  329: { width: "255", aspect: "40", diameter: "20" },
  330: { width: "255", aspect: "40", diameter: "21" },
  331: { width: "255", aspect: "45", diameter: "17" },
  332: { width: "255", aspect: "45", diameter: "18" },
  333: { width: "255", aspect: "45", diameter: "19" },
  334: { width: "255", aspect: "45", diameter: "20" },
  335: { width: "255", aspect: "50", diameter: "16" },
  336: { width: "255", aspect: "50", diameter: "17" },
  337: { width: "255", aspect: "50", diameter: "18" },
  338: { width: "255", aspect: "50", diameter: "19" },
  339: { width: "255", aspect: "50", diameter: "20" },
  340: { width: "255", aspect: "50", diameter: "21" },
  341: { width: "255", aspect: "55", diameter: "17" },
  342: { width: "255", aspect: "55", diameter: "18" },
  343: { width: "255", aspect: "55", diameter: "19" },
  344: { width: "255", aspect: "55", diameter: "20" },
  345: { width: "255", aspect: "60", diameter: "15" },
  346: { width: "255", aspect: "60", diameter: "17" },
  347: { width: "255", aspect: "60", diameter: "18" },
  348: { width: "255", aspect: "60", diameter: "19" },
  349: { width: "255", aspect: "65", diameter: "15" },
  350: { width: "255", aspect: "65", diameter: "16" },
  351: { width: "255", aspect: "65", diameter: "17" },
  352: { width: "255", aspect: "65", diameter: "18" },
  353: { width: "255", aspect: "70", diameter: "15" },
  354: { width: "255", aspect: "70", diameter: "16" },
  355: { width: "255", aspect: "70", diameter: "17" },
  356: { width: "255", aspect: "70", diameter: "18" },
  357: { width: "255", aspect: "75", diameter: "17" },
  358: { width: "255", aspect: "80", diameter: "17" },
  359: { width: "255", aspect: "85", diameter: "16" },
  360: { width: "265", aspect: "30", diameter: "19" },
  361: { width: "265", aspect: "30", diameter: "20" },
  362: { width: "265", aspect: "30", diameter: "21" },
  363: { width: "265", aspect: "30", diameter: "22" },
  364: { width: "265", aspect: "35", diameter: "18" },
  365: { width: "265", aspect: "35", diameter: "19" },
  366: { width: "265", aspect: "35", diameter: "20" },
  367: { width: "265", aspect: "35", diameter: "21" },
  368: { width: "265", aspect: "35", diameter: "22" },
  369: { width: "265", aspect: "40", diameter: "17" },
  370: { width: "265", aspect: "40", diameter: "18" },
  371: { width: "265", aspect: "40", diameter: "19" },
  372: { width: "265", aspect: "40", diameter: "20" },
  373: { width: "265", aspect: "40", diameter: "21" },
  374: { width: "265", aspect: "40", diameter: "22" },
  375: { width: "265", aspect: "45", diameter: "18" },
  376: { width: "265", aspect: "45", diameter: "19" },
  377: { width: "265", aspect: "45", diameter: "20" },
  378: { width: "265", aspect: "45", diameter: "21" },
  379: { width: "265", aspect: "50", diameter: "15" },
  380: { width: "265", aspect: "50", diameter: "19" },
  381: { width: "265", aspect: "50", diameter: "20" },
  382: { width: "265", aspect: "55", diameter: "17" },
  383: { width: "265", aspect: "55", diameter: "19" },
  384: { width: "265", aspect: "60", diameter: "18" },
  385: { width: "265", aspect: "60", diameter: "20" },
  386: { width: "265", aspect: "65", diameter: "17" },
  387: { width: "265", aspect: "65", diameter: "18" },
  388: { width: "265", aspect: "70", diameter: "15" },
  389: { width: "265", aspect: "70", diameter: "16" },
  390: { width: "265", aspect: "70", diameter: "17" },
  391: { width: "265", aspect: "70", diameter: "18" },
  392: { width: "265", aspect: "75", diameter: "15" },
  393: { width: "265", aspect: "75", diameter: "16" },
  394: { width: "275", aspect: "25", diameter: "22" },
  395: { width: "275", aspect: "25", diameter: "24" },
  396: { width: "275", aspect: "30", diameter: "19" },
  397: { width: "275", aspect: "30", diameter: "20" },
  398: { width: "275", aspect: "30", diameter: "21" },
  399: { width: "275", aspect: "35", diameter: "15" },
  400: { width: "275", aspect: "35", diameter: "17" },
  401: { width: "275", aspect: "35", diameter: "18" },
  402: { width: "275", aspect: "35", diameter: "19" },
  403: { width: "275", aspect: "35", diameter: "20" },
  404: { width: "275", aspect: "35", diameter: "21" },
  405: { width: "275", aspect: "35", diameter: "22" },
  406: { width: "275", aspect: "40", diameter: "17" },
  407: { width: "275", aspect: "40", diameter: "18" },
  408: { width: "275", aspect: "40", diameter: "19" },
  409: { width: "275", aspect: "40", diameter: "20" },
  410: { width: "275", aspect: "40", diameter: "21" },
  411: { width: "275", aspect: "40", diameter: "22" },
  412: { width: "275", aspect: "45", diameter: "18" },
  413: { width: "275", aspect: "45", diameter: "19" },
  414: { width: "275", aspect: "45", diameter: "20" },
  415: { width: "275", aspect: "45", diameter: "21" },
  416: { width: "275", aspect: "45", diameter: "22" },
  417: { width: "275", aspect: "50", diameter: "15" },
  418: { width: "275", aspect: "50", diameter: "19" },
  419: { width: "275", aspect: "50", diameter: "20" },
  420: { width: "275", aspect: "50", diameter: "21" },
  421: { width: "275", aspect: "50", diameter: "22" },
  422: { width: "275", aspect: "55", diameter: "17" },
  423: { width: "275", aspect: "55", diameter: "18" },
  424: { width: "275", aspect: "55", diameter: "19" },
  425: { width: "275", aspect: "55", diameter: "20" },
  426: { width: "275", aspect: "60", diameter: "15" },
  427: { width: "275", aspect: "60", diameter: "17" },
  428: { width: "275", aspect: "60", diameter: "18" },
  429: { width: "275", aspect: "60", diameter: "20" },
  430: { width: "275", aspect: "65", diameter: "17" },
  431: { width: "275", aspect: "65", diameter: "18" },
  432: { width: "275", aspect: "65", diameter: "20" },
  433: { width: "275", aspect: "70", diameter: "16" },
  434: { width: "275", aspect: "70", diameter: "17" },
  435: { width: "275", aspect: "70", diameter: "18" },
  436: { width: "285", aspect: "25", diameter: "20" },
  437: { width: "285", aspect: "25", diameter: "22" },
  438: { width: "285", aspect: "30", diameter: "18" },
  439: { width: "285", aspect: "30", diameter: "19" },
  440: { width: "285", aspect: "30", diameter: "20" },
  441: { width: "285", aspect: "30", diameter: "21" },
  442: { width: "285", aspect: "30", diameter: "22" },
  443: { width: "285", aspect: "30", diameter: "24" },
  444: { width: "285", aspect: "35", diameter: "18" },
  445: { width: "285", aspect: "35", diameter: "19" },
  446: { width: "285", aspect: "35", diameter: "20" },
  447: { width: "285", aspect: "35", diameter: "21" },
  448: { width: "285", aspect: "35", diameter: "22" },
  449: { width: "285", aspect: "35", diameter: "24" },
  450: { width: "285", aspect: "40", diameter: "17" },
  451: { width: "285", aspect: "40", diameter: "18" },
  452: { width: "285", aspect: "40", diameter: "19" },
  453: { width: "285", aspect: "40", diameter: "20" },
  454: { width: "285", aspect: "40", diameter: "21" },
  455: { width: "285", aspect: "40", diameter: "22" },
  456: { width: "285", aspect: "40", diameter: "24" },
  457: { width: "285", aspect: "45", diameter: "18" },
  458: { width: "285", aspect: "45", diameter: "19" },
  459: { width: "285", aspect: "45", diameter: "20" },
  460: { width: "285", aspect: "45", diameter: "21" },
  461: { width: "285", aspect: "45", diameter: "22" },
  462: { width: "285", aspect: "50", diameter: "18" },
  463: { width: "285", aspect: "50", diameter: "20" },
  464: { width: "285", aspect: "55", diameter: "18" },
  465: { width: "285", aspect: "55", diameter: "20" },
  466: { width: "285", aspect: "60", diameter: "16" },
  467: { width: "285", aspect: "60", diameter: "18" },
  468: { width: "285", aspect: "60", diameter: "20" },
  469: { width: "285", aspect: "65", diameter: "17" },
  470: { width: "285", aspect: "65", diameter: "18" },
  471: { width: "285", aspect: "65", diameter: "20" },
  472: { width: "285", aspect: "70", diameter: "17" },
  473: { width: "285", aspect: "70", diameter: "18" },
  474: { width: "285", aspect: "75", diameter: "16" },
  475: { width: "285", aspect: "75", diameter: "17" },
  476: { width: "285", aspect: "75", diameter: "18" },
  477: { width: "285", aspect: "75", diameter: "24.5" },
  478: { width: "295", aspect: "25", diameter: "19" },
  479: { width: "295", aspect: "25", diameter: "20" },
  480: { width: "295", aspect: "25", diameter: "21" },
  481: { width: "295", aspect: "25", diameter: "22" },
  482: { width: "295", aspect: "30", diameter: "18" },
  483: { width: "295", aspect: "30", diameter: "19" },
  484: { width: "295", aspect: "30", diameter: "20" },
  485: { width: "295", aspect: "30", diameter: "21" },
  486: { width: "295", aspect: "30", diameter: "22" },
  487: { width: "295", aspect: "35", diameter: "17" },
  488: { width: "295", aspect: "35", diameter: "18" },
  489: { width: "295", aspect: "35", diameter: "19" },
  490: { width: "295", aspect: "35", diameter: "20" },
  491: { width: "295", aspect: "35", diameter: "21" },
  492: { width: "295", aspect: "35", diameter: "24" },
  493: { width: "295", aspect: "40", diameter: "18" },
  494: { width: "295", aspect: "40", diameter: "19" },
  495: { width: "295", aspect: "40", diameter: "20" },
  496: { width: "295", aspect: "40", diameter: "21" },
  497: { width: "295", aspect: "40", diameter: "22" },
  498: { width: "295", aspect: "45", diameter: "18" },
  499: { width: "295", aspect: "45", diameter: "19" },
  500: { width: "295", aspect: "45", diameter: "20" },
  501: { width: "295", aspect: "50", diameter: "15" },
  502: { width: "295", aspect: "50", diameter: "16" },
  503: { width: "295", aspect: "50", diameter: "20" },
  504: { width: "295", aspect: "55", diameter: "15" },
  505: { width: "295", aspect: "55", diameter: "20" },
  506: { width: "295", aspect: "60", diameter: "20" },
  507: { width: "295", aspect: "65", diameter: "18" },
  508: { width: "295", aspect: "65", diameter: "20" },
  509: { width: "295", aspect: "70", diameter: "17" },
  510: { width: "295", aspect: "70", diameter: "18" },
  511: { width: "295", aspect: "75", diameter: "16" },
  512: { width: "295", aspect: "75", diameter: "22.5" },
  513: { width: "305", aspect: "25", diameter: "20" },
  514: { width: "305", aspect: "25", diameter: "21" },
  515: { width: "305", aspect: "25", diameter: "22" },
  516: { width: "305", aspect: "30", diameter: "18" },
  517: { width: "305", aspect: "30", diameter: "19" },
  518: { width: "305", aspect: "30", diameter: "20" },
  519: { width: "305", aspect: "30", diameter: "21" },
  520: { width: "305", aspect: "30", diameter: "22" },
  521: { width: "305", aspect: "30", diameter: "26" },
  522: { width: "305", aspect: "35", diameter: "18" },
  523: { width: "305", aspect: "35", diameter: "19" },
  524: { width: "305", aspect: "35", diameter: "20" },
  525: { width: "305", aspect: "35", diameter: "24" },
  526: { width: "305", aspect: "40", diameter: "22" },
  527: { width: "305", aspect: "40", diameter: "23" },
  528: { width: "305", aspect: "45", diameter: "22" },
  529: { width: "305", aspect: "50", diameter: "20" },
  530: { width: "305", aspect: "55", diameter: "20" },
  531: { width: "305", aspect: "60", diameter: "18" },
  532: { width: "305", aspect: "65", diameter: "17" },
  533: { width: "305", aspect: "65", diameter: "18" },
  534: { width: "305", aspect: "70", diameter: "16" },
  535: { width: "305", aspect: "70", diameter: "17" },
  536: { width: "305", aspect: "70", diameter: "18" },
  537: { width: "315", aspect: "25", diameter: "19" },
  538: { width: "315", aspect: "25", diameter: "20" },
  539: { width: "315", aspect: "25", diameter: "22" },
  540: { width: "315", aspect: "30", diameter: "18" },
  541: { width: "315", aspect: "30", diameter: "19" },
  542: { width: "315", aspect: "30", diameter: "20" },
  543: { width: "315", aspect: "30", diameter: "21" },
  544: { width: "315", aspect: "35", diameter: "17" },
  545: { width: "315", aspect: "35", diameter: "18" },
  546: { width: "315", aspect: "35", diameter: "19" },
  547: { width: "315", aspect: "35", diameter: "20" },
  548: { width: "315", aspect: "35", diameter: "24" },
  549: { width: "315", aspect: "40", diameter: "18" },
  550: { width: "315", aspect: "40", diameter: "19" },
  551: { width: "315", aspect: "40", diameter: "21" },
  552: { width: "315", aspect: "50", diameter: "20" },
  553: { width: "315", aspect: "70", diameter: "17" },
  554: { width: "315", aspect: "75", diameter: "16" },
  555: { width: "325", aspect: "25", diameter: "20" },
  556: { width: "325", aspect: "25", diameter: "21" },
  557: { width: "325", aspect: "30", diameter: "19" },
  558: { width: "325", aspect: "30", diameter: "20" },
  559: { width: "325", aspect: "30", diameter: "21" },
  560: { width: "325", aspect: "35", diameter: "20" },
  561: { width: "325", aspect: "35", diameter: "22" },
  562: { width: "325", aspect: "40", diameter: "21" },
  563: { width: "325", aspect: "45", diameter: "18" },
  564: { width: "325", aspect: "45", diameter: "24" },
  565: { width: "325", aspect: "50", diameter: "15" },
  566: { width: "325", aspect: "50", diameter: "22" },
  567: { width: "325", aspect: "55", diameter: "22" },
  568: { width: "325", aspect: "60", diameter: "18" },
  569: { width: "325", aspect: "60", diameter: "20" },
  570: { width: "325", aspect: "60", diameter: "22" },
  571: { width: "325", aspect: "65", diameter: "18" },
  572: { width: "335", aspect: "25", diameter: "20" },
  573: { width: "335", aspect: "25", diameter: "22" },
  574: { width: "335", aspect: "30", diameter: "18" },
  575: { width: "335", aspect: "30", diameter: "20" },
  576: { width: "335", aspect: "35", diameter: "17" },
  577: { width: "335", aspect: "55", diameter: "22" },
  578: { width: "345", aspect: "25", diameter: "20" },
  579: { width: "345", aspect: "30", diameter: "18" },
  580: { width: "345", aspect: "30", diameter: "19" },
  581: { width: "345", aspect: "30", diameter: "20" },
  582: { width: "345", aspect: "35", diameter: "15" },
  583: { width: "345", aspect: "35", diameter: "18" },
  584: { width: "345", aspect: "35", diameter: "19" },
  585: { width: "345", aspect: "40", diameter: "17" },
  586: { width: "345", aspect: "75", diameter: "16" },
  587: { width: "355", aspect: "25", diameter: "21" },
  588: { width: "355", aspect: "30", diameter: "19" },
  589: { width: "365", aspect: "75", diameter: "16" },
  590: { width: "405", aspect: "25", diameter: "24" },
};
