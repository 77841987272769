import { NgModule } from "@angular/core";
import { SQLite } from "@ionic-native/sqlite/ngx";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";

import { FileTransfer } from "@ionic-native/file-transfer/ngx";

@NgModule({
  providers: [SQLite, BarcodeScanner, FileTransfer],
})
export class IonicNativeProvidersModule {}
