import { createAction, props } from "@ngrx/store";
import {
  SearchAPIParams,
  TireInventorySearchResults,
  OfflineTireInventoryClientSideFormat,
  OnlineTireInventoryClientSideFormat,
} from "./inventory.models";

export const SEARCH_TIRE_INVENTORY = createAction(
  "[SEARCH] HTTP GET Request",
  props<{ paramProps: SearchAPIParams }>()
);

export const SEARCH_UPDATE_SEGMENT_VALUE = createAction(
  "[SEARCH] Update Segment Value ",
  props<{ segmentValue: "VERIFIED" | "NON_VERIFIED" }>()
);

export const UPDATE_TIRE_SEARCH_RESULTS = createAction(
  "[INVENTORY] Update Online Inventory Entity State",
  props<{ tireSearchResultsSet: TireInventorySearchResults[] }>()
);

export const CLEAR_TIRE_SEARCH_RESULTS = createAction(
  "[INVENTORY] Clear Online Inventory Entity State"
);

export const ADD_TIRE_TO_LOCAL_INVENTORY = createAction(
  "[TIRE-CRUD] Add tire to local inventory",
  props<{ tireInfo: OfflineTireInventoryClientSideFormat }>()
);

export const UPLOAD_LOCAL_INVENTORY_TO_SERVER = createAction(
  "[TIRE-CRUD] Bulk upload inventory"
);

export const FETCH_LAST_UNIQUE_ID = createAction(
  "[TIRE-CRUD] Fetch last unique ID from server"
);

export const UPDATE_LAST_UNIQUE_ID_IN_STORE = createAction(
  "[TIRE-CRUD] FETCH_LAST_UNIQUE_ID success",
  props<{ lastUniqueID: string }>()
);

export const UPDATE_API_URI_BASE = createAction(
  "[INVENTROY] Update API Base URI",
  props<{ baseURI: string }>()
);

export const POPULATE_LOCAL_INVENTORY = createAction(
  "[SEARCH] Populate local inventory"
);

export const POPULATE_LOCAL_INVENTORY_SUCCESS = createAction(
  "[SEARCH] Populate local inventory success",
  props<{ localInventory: OfflineTireInventoryClientSideFormat[] }>()
);

export const CLEAR_OFFLINE_INVENTORY_FROM_LOCAL_DB = createAction(
  "[INVENTORY] Clear Offline Inventory From Local Database"
);

export const CLEAR_OFFLINE_INVENTORY_FROM_STORE = createAction(
  "[INVENTORY] Clear Offline Inventory From Entity Store"
);

export const ONLINE_INVENTORY_SET_TIRE = createAction(
  "[TIRE-CRUD] Set Tire",
  props<{ tireInfo: OnlineTireInventoryClientSideFormat }>()
);

export const ONLINE_INVENTORY_DELETE_TIRE = createAction(
  "[TIRE-CRUD] Delete Tire",
  props<{ tireID: string; userID: string }>()
);

export const ONLINE_MOVE_INVENTORY = createAction(
  "[TIRE-CRUD] Move Inventory",
  props<{ tire_id: string[]; location: string }>()
);
