import { createReducer, on, Action } from "@ngrx/store";
import produce from "immer";

import {
  ITabPage,
  AboutDevice,
  AlertComponentOptions,
  AutoDismissingToastComponentOptions,
} from "./layout.models";
import * as fromLayout from "./layout.actions";

export const layoutFeatureKey = "layout";

export interface LayoutStoreState {
  tabPages: ITabPage[];
  deviceInfo: AboutDevice;
  alertComponentOptions: AlertComponentOptions;
  autoDismissingToastComponentOptions: AutoDismissingToastComponentOptions;
  splitpaneIsVisible: boolean;
  splitpaneIsEnabled: boolean;
}

const initialState: LayoutStoreState = {
  tabPages: [
    {
      pageName: "Inventory",
      tabID: "inventory",
      url: "/app/tabs/inventory",
      icon: "search-outline",
    },
    {
      pageName: "Actions",
      tabID: "quick-actions",
      url: "/app/tabs/quick-actions",
      icon: "link-outline",
    },
    {
      pageName: "Settings",
      tabID: "settings",
      url: "/app/tabs/settings",
      icon: "settings-outline",
    },
  ],
  deviceInfo: null,
  alertComponentOptions: null,
  autoDismissingToastComponentOptions: null,
  splitpaneIsVisible: null,
  splitpaneIsEnabled: true,
};

const layoutReducer = createReducer(
  initialState,
  on(
    fromLayout.LAYOUT_UPDATE_SPLITPANE_VISIBILITY_STATE,
    (layoutStore, { splitpaneIsVisible }) =>
      produce(layoutStore, (state) => {
        state.splitpaneIsVisible = splitpaneIsVisible;
      })
  ),
  on(
    fromLayout.LAYOUT_UPDATE_SPLITPANE_STATUS,
    (layoutStore, { splitpaneIsEnabled }) =>
      produce(layoutStore, (state) => {
        state.splitpaneIsEnabled = splitpaneIsEnabled;
      })
  ),

  on(fromLayout.DEVICE_INFO_AVAILABLE, (layoutStore, { deviceInfo }) =>
    produce(layoutStore, (state) => {
      state.deviceInfo = deviceInfo;
    })
  ),

  on(
    fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT,
    (layoutStore, { alertComponentOptions }) =>
      produce(layoutStore, (state) => {
        state.alertComponentOptions = alertComponentOptions;
      })
  )
);

export function reducer(state: LayoutStoreState, action: Action) {
  return layoutReducer(state, action);
}
