export interface EntityOptions {
  /**
   * @param keyName Name of the property that will be used as the entityID.
   * Must be a unique string.
   */
  keyName: string;
}

export interface IEntity<T> {
  ids: string[];
  entities: Record<string, T>;
  options: EntityOptions;
}

export enum AugmentedBOOLEAN {
  FALSE = 0,
  TRUE = 1,
}

export enum AugmentedBOOLEANString {
  FALSE = "0",
  TRUE = "1",
}
