import { createAction, props } from "@ngrx/store";
import { OfflineTireInventoryClientSideFormat } from "../inventory.models";

export const POPULATE_LOCAL_TIRE_INFO = createAction(
  "[TIRE-CRUD] Populate local data into form",
  props<{ tireInfo: OfflineTireInventoryClientSideFormat }>()
);

export const PATCH_TIRE_CRUD_FORM = createAction(
  "[TIRE-CRUD] Patch tire CRUD form",
  props<{ tireInfoPartial: Partial<OfflineTireInventoryClientSideFormat> }>()
);

export const RESET_TIRE_CRUD_FORM = createAction("[TIRE-CRUD] Reset Form");
