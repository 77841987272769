import { createAction, props } from "@ngrx/store";
import { AuthStoreState } from "./auth.reducer";
import { SignInForm } from "./auth.models";

export const INIT = createAction("[AUTH] Load User from Local Database");
export const LOGIN = createAction(
  "[AUTH] LogIn",
  props<{
    signInFormValues: SignInForm;
    returnToUrl: string;
  }>()
);
export const LOGOUT = createAction("[AUTH] LogOut");

export const AUTH_UPDATE_STATE = createAction(
  "[AUTH] Update Auth state",
  props<Pick<AuthStoreState, "user">>()
);
