import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import * as fromLayout from "../layout.actions";
import { ITabPage } from "../layout.models";
import { selectSplitpaneIsEnabled, selectTabPages } from "../layout.selectors";

@Component({
  selector: "app-split-pane",
  templateUrl: "./split-pane.component.html",
  styleUrls: ["./styles/split-pane.component.scss"],
})
export class SplitPaneComponent implements OnInit {
  tabPages$: Observable<ITabPage[]>;
  splitpaneIsEnabled$: Observable<boolean>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.tabPages$ = this.store.pipe(select(selectTabPages));
    this.splitpaneIsEnabled$ = this.store.pipe(
      select(selectSplitpaneIsEnabled)
    );
  }

  splitpaneVisibilityChanged($event: any) {
    this.store.dispatch(
      fromLayout.LAYOUT_UPDATE_SPLITPANE_VISIBILITY_STATE({
        splitpaneIsVisible: Boolean($event.detail.visible),
      })
    );
  }
}
