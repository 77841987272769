import { NgModule } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { RootStoreModule } from "./store.module";

import { HttpClientModule } from "@angular/common/http";
import { FirebaseModule } from "./firebase.module";
import { IonicNativeProvidersModule } from "./ionic-native.module";
import { SplitPaneComponent } from "./layout/split-pane/split-pane.component";
@NgModule({
  declarations: [AppComponent, SplitPaneComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({ mode: "md" }),
    IonicNativeProvidersModule,
    IonicStorageModule.forRoot({
      name: "__reusedTireDB",
      driverOrder: ["indexeddb", "sqlite", "websql"],
    }),
    RootStoreModule,
    AppRoutingModule,
    FirebaseModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
