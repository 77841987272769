import { createReducer, on, Action } from "@ngrx/store";
import produce from "immer";

import * as fromAuth from "./auth.actions";
import { User } from "./auth.models";

export const authFeatureKey = "auth";
export interface AuthStoreState {
  user: User; // null if logged out
}

const initialState: AuthStoreState = {
  user: null,
};

const authReducer = createReducer(
  initialState,
  on(fromAuth.AUTH_UPDATE_STATE, (authStore, { user }) =>
    produce(authStore, (state) => {
      state.user = user;
    })
  )
);

export function reducer(state: AuthStoreState, action: Action) {
  return authReducer(state, action);
}
