import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import * as fromAuth from "./auth.actions";
import * as fromLayout from "../../layout/layout.actions";
import * as fromInventory from "../tabs/inventory/inventory.actions";

import { of, from } from "rxjs";
import {
  exhaustMap,
  switchMap,
  catchError,
  withLatestFrom,
  tap,
  map,
} from "rxjs/operators";
import { HttpParams, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import {
  DATABASE_AUTH_OBJECT_LOCATION,
  User,
  IAuthSuccess,
} from "./auth.models";

import { Storage } from "@ionic/storage";

@Injectable()
export class AuthEffects {
  loadUserFromLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuth.INIT),
        switchMap(() => this.storage.get(DATABASE_AUTH_OBJECT_LOCATION)),
        map((user: User) => {
          return fromAuth.AUTH_UPDATE_STATE({
            user,
          });
        })
      ),
    {
      dispatch: true,
      useEffectsErrorHandler: true,
    }
  );

  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuth.LOGIN),
        exhaustMap(({ signInFormValues, returnToUrl }) => {
          const { email, password } = signInFormValues;
          // PhoneGap app sent password as query parameter
          let params = new HttpParams().set("username", email);
          params = params.set("password", password); // HttpParams is immutable
          return this.http
            .get<IAuthSuccess>(
              `https://invoicing.reusedtire.com/index.php/sessions/ajax/login`,

              {
                params,
              }
            )
            .pipe(
              withLatestFrom(of(email), of(returnToUrl)),
              catchError(() => {
                // ToDo: Old API does not return a specific error message
                return of("ERROR");
              })
            );
        }),
        switchMap(([response, email, returnToUrl]) => {
          return typeof response !== "string"
            ? // Auth Success
              of(
                fromAuth.AUTH_UPDATE_STATE({
                  user: {
                    user_id: Number(response.user_id),
                    token: response.token,
                    email: email as string,
                  },
                })
              ).pipe(
                tap(async ({ user }) => {
                  console.log(`From effects, returnURL: ${returnToUrl}`);
                  await this.storage.set(DATABASE_AUTH_OBJECT_LOCATION, user);
                  await this.router.navigateByUrl(returnToUrl as string);
                })
              )
            : // Auth Error
              of(
                fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                  alertComponentOptions: {
                    title: "Authentication Error",
                    message: "Please verify your credentials and try again",
                    buttons: {
                      cancel: {
                        buttonText: "OK",
                        buttonAction: async () => {
                          /* no-op */
                        },
                      },
                      confirm: null,
                    },
                  },
                })
              );
        })
      ),
    {
      dispatch: true,
      useEffectsErrorHandler: true,
    }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuth.LOGOUT),
        exhaustMap(() => {
          const clearLocalAuthData = this.storage.remove(
            DATABASE_AUTH_OBJECT_LOCATION
          );
          const routeToHomePage = this.router.navigateByUrl("/welcome");

          return from(Promise.all([clearLocalAuthData, routeToHomePage])).pipe(
            switchMap(() => [
              fromAuth.AUTH_UPDATE_STATE({
                user: null,
              }),
              fromLayout.LAYOUT_PRESENT_AUTO_DISMISSING_TOAST_COMPONENT({
                autoDismissingToastComponentOptions: {
                  message: "Successfully Logged Out...",
                  duration: 2500,
                },
              }),

              fromInventory.CLEAR_OFFLINE_INVENTORY_FROM_LOCAL_DB(),
            ])
          );
        })
      ),
    {
      dispatch: true,
      useEffectsErrorHandler: true,
    }
  );

  constructor(
    private actions$: Actions,
    private storage: Storage,
    private router: Router,
    private http: HttpClient
  ) {}
}
