import {
  createFormGroupState,
  updateGroup,
  validate,
  onNgrxForms,
  wrapReducerWithFormStateUpdate,
  setValue,
  reset,
} from "ngrx-forms";

import { required } from "ngrx-forms/validation";
import { createReducer, on } from "@ngrx/store";
import {
  OfflineTireInventoryClientSideFormat,
  DEFAULT_IMAGE_URL,
} from "../inventory.models";

import * as fromTireInventoryForm from "./tire-crud.actions";
import { AugmentedBOOLEAN } from "../../../../libs";
import produce from "immer";

export const tireCRUDFormKey = "tireCRUDForm";

const tireCRUDFormInitialState: OfflineTireInventoryClientSideFormat = {
  brand: "",
  brandname: "",
  diameter: "",
  imageurl: Array(5).fill(
    {
      webviewFriendlyPath: DEFAULT_IMAGE_URL,
      filesystemFriendlyPath: "",
    },
    0,
    5
  ),
  lighttruck: AugmentedBOOLEAN.FALSE,
  is_verified: AugmentedBOOLEAN.TRUE,
  loadindex: "",
  location: "",
  model: "",
  notes: "",
  price: 0,
  quantity: 1,
  rate: "",
  ratio: "",
  remaining_tred: 32,
  rotational: AugmentedBOOLEAN.FALSE,
  season: "",
  tireply: "",
  width: "",
  id: null,
  user_id: null,
  username: null,
  unique: null,
};

const validateTireCRUDForm = updateGroup<OfflineTireInventoryClientSideFormat>({
  price: validate(required),
  quantity: validate(required),
  brand: validate(required),
  location: validate(required),
  season: validate(required),
  remaining_tred: validate(required),
  width: validate(required),
  ratio: validate(required),
  diameter: validate(required),
});

const rawReducer = createReducer(
  createFormGroupState(tireCRUDFormKey, tireCRUDFormInitialState),
  onNgrxForms(),
  on(
    fromTireInventoryForm.POPULATE_LOCAL_TIRE_INFO,
    (tireCRUDFormState, { tireInfo }) => setValue(tireCRUDFormState, tireInfo)
  ),
  on(fromTireInventoryForm.RESET_TIRE_CRUD_FORM, (tireCRUDFormState, {}) => {
    const formWithInitialValues = setValue(tireCRUDFormState, {
      ...tireCRUDFormInitialState,
    });

    return reset(formWithInitialValues);
  }),
  on(
    fromTireInventoryForm.PATCH_TIRE_CRUD_FORM,
    (tireCRUDFormState, { tireInfoPartial }) =>
      setValue(
        tireCRUDFormState,
        produce(tireCRUDFormState.value, (draft) => {
          Object.keys(tireInfoPartial).forEach((key) => {
            draft[key] = tireInfoPartial[key];
          });
        })
      )
  )
);

// wrapReducerWithFormStateUpdate calls the update function
// after the given reducer; you can wrap this reducer again
// if you have multiple forms in your state
export const reducer = wrapReducerWithFormStateUpdate(
  rawReducer,
  // point to the form state to update
  (s) => s,
  // this function is always called after the reducer
  validateTireCRUDForm
);
