import { createAction, props } from "@ngrx/store";
import { LayoutStoreState } from "./layout.reducer";

export const DEVICE_INFO_AVAILABLE = createAction(
  "[ABOUT-DEVICE] add available device information",
  props<Pick<LayoutStoreState, "deviceInfo">>()
);

export const LAYOUT_PRESENT_ALERT_COMPONENT = createAction(
  "[LAYOUT] Alert Component",
  props<Pick<LayoutStoreState, "alertComponentOptions">>()
);

export const LAYOUT_PRESENT_AUTO_DISMISSING_TOAST_COMPONENT = createAction(
  "[LAYOUT] Toast Component",
  props<Pick<LayoutStoreState, "autoDismissingToastComponentOptions">>()
);

export const LAYOUT_UPDATE_SPLITPANE_VISIBILITY_STATE = createAction(
  "[LAYOUT] Update Splitpane visibility state",
  props<Pick<LayoutStoreState, "splitpaneIsVisible">>()
);

export const LAYOUT_UPDATE_SPLITPANE_STATUS = createAction(
  "[LAYOUT] Update Splitpane enabled/disabled status",
  props<Pick<LayoutStoreState, "splitpaneIsEnabled">>()
);
