import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./pages/auth/auth.guard";

const routes: Routes = [
  {
    path: "welcome",
    loadChildren: () =>
      import("./pages/landing/welcome/welcome.module").then(
        (m) => m.WelcomePageModule
      ),
  },
  {
    path: "signin",
    loadChildren: () =>
      import("./pages/auth/signin/signin.module").then(
        (m) => m.SigninPageModule
      ),
  },

  {
    path: "app",
    loadChildren: () =>
      import("./layout/tabs/tabs.module").then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },

  {
    path: "",
    redirectTo: "welcome",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
