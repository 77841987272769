import { NgModule } from "@angular/core";

import { AngularFireModule } from "@angular/fire";
import { AngularFireAnalyticsModule, CONFIG } from "@angular/fire/analytics";
import { AngularFirePerformanceModule } from "@angular/fire/performance";
import { environment } from "../environments/environment";

@NgModule({
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
  ],
  providers: [
    {
      provide: CONFIG,
      useValue: {
        allow_ad_personalization_signals: false,
        // anonymize_ip: true,
      },
    },
  ],
})
export class FirebaseModule {}
