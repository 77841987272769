import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { RootStoreState } from "src/app/store.models";
import { selectLoggedInUser } from "./auth.selectors";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store<RootStoreState>, public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    console.warn(`From guard, url: ${state.url}`);
    return this.store.pipe(
      select(selectLoggedInUser),
      map((user) => Boolean(user)),
      tap((user) => {
        if (!user) {
          this.router.navigate(["/signin"], {
            queryParams: {
              return: state.url,
            },
          });
        }
      })
    );
  }
}
