import produce from "immer";
import { IEntity, EntityOptions } from "./entity.models";

export const entityInitialStateFactory = <T>(
  options: EntityOptions
): IEntity<T> => {
  return {
    ids: [],
    entities: {},
    options,
  };
};

export const transformArrayDataToEntity = <T>(
  inputArray: T[],
  options: EntityOptions
): IEntity<T> => {
  return inputArray.reduce(
    (entityState, data) =>
      produce(entityState, (draft) => {
        // entityState
      }),
    {
      ids: [],
      entities: {},
      options,
    } as IEntity<T>
  );
};
