import { AugmentedBOOLEAN, AugmentedBOOLEANString } from "../../../libs";
import { CameraPhoto } from "@capacitor/core";

export const DATABASE_INVENTORY_IDS_LOCATION = "localInventoryIDsArray";
export const DATABASE_LAST_UNIQUEID_LOCATION = "lastUniqueID";
export const DEFAULT_IMAGE_URL = "assets/tire-default-thumbnails/tire05.png";

export interface ImageURLs {
  webviewFriendlyPath: CameraPhoto["webPath"]; // For image src
  filesystemFriendlyPath: CameraPhoto["path"]; // For FileTransferPlugin
}
export interface SearchAPIParams {
  type: "searchtire";
  keyword: string;
}

export interface SearchAPIResponse {
  result: "SUCCESS" | "ERROR";
  data: TireInventorySearchResults[];
}

interface InsertNewInventoryResponse {
  id: number;
  message: string;
  result: "SUCCESS" | "ERROR";
  unique_id: number;
}

export interface SetTireResponse {
  message: string;
  result: "SUCCESS" | "ERROR";
}

export type BulkInsertNewInventoryResponse = InsertNewInventoryResponse[];

export interface FetchLastUniqueIDAPIResponse {
  result: "SUCCESS" | "ERROR";
  last_id: "string";
}

export interface DeleteOnlineInventoryAPIResponse {
  result: "SUCCESS" | "ERROR";
  data: number;
}

export interface MoveOnlineInventoryResponse {
  message: string;
  result: "SUCCESS" | "ERROR";
}

export interface TireInventorySearchResults {
  brand: string;
  brandname: string; // tire model
  deleted: AugmentedBOOLEANString;
  deleted_date?: string;
  deleted_by: string;
  diameter: string;
  id: string;
  imageurl: string;
  lighttruck: AugmentedBOOLEANString;
  loadindex: string;
  location: string;
  model: string;
  notes: string;
  price: string;
  quantity: string;
  rate: string;
  ratio: string;
  remaining_tred: string;
  rotational: AugmentedBOOLEANString;
  season: string;
  timestamp: string;
  tireply: string;
  unique_id: string;
  user_id: string;
  username: string;
  width: string;
  is_verified: AugmentedBOOLEANString;
}

interface OfflineTireInventoryBaseFormat {
  brand: string;
  brandname: string; // tire model
  diameter: string;
  id: number;
  imageurl: string | ImageURLs[];
  lighttruck: AugmentedBOOLEAN;
  loadindex: string;
  location: string;
  model: string;
  notes: string;
  price: number;
  quantity: number;
  rate: string;
  ratio: string;
  remaining_tred: number;
  rotational: AugmentedBOOLEAN;
  season: string;
  tireply: string;
  user_id: number;
  username: string;
  unique: string;
  width: string;
  is_verified: AugmentedBOOLEAN;
}

export interface OfflineTireInventoryServerSideFormat
  extends OfflineTireInventoryBaseFormat {
  imageurl: string;
}

export interface OfflineTireInventoryClientSideFormat
  extends OfflineTireInventoryBaseFormat {
  imageurl: ImageURLs[];
}

// tslint:disable: variable-name
interface OnlineTireInventoryBaseFormat {
  tire_id: TireInventorySearchResults["id"];
  user_id: TireInventorySearchResults["user_id"];
  width: TireInventorySearchResults["width"];
  ratio: TireInventorySearchResults["ratio"];
  diameter: TireInventorySearchResults["diameter"];
  brand: TireInventorySearchResults["brand"];
  brandname: TireInventorySearchResults["brandname"];
  model: TireInventorySearchResults["model"];
  imageurl: TireInventorySearchResults["imageurl"] | ImageURLs[];
  loadindex: TireInventorySearchResults["loadindex"];
  location: TireInventorySearchResults["location"];
  notes: TireInventorySearchResults["notes"];
  price: TireInventorySearchResults["price"];
  quantity: TireInventorySearchResults["quantity"];
  rate: TireInventorySearchResults["rate"];
  remaining_tred: TireInventorySearchResults["remaining_tred"];
  lighttruck: AugmentedBOOLEAN;
  rotational: AugmentedBOOLEAN;
  is_verified: AugmentedBOOLEAN;
  season: TireInventorySearchResults["season"];
  tireply: TireInventorySearchResults["tireply"];
}

// tslint:enable: variable-name

export interface OnlineTireInventoryServerSideFormat
  extends OnlineTireInventoryBaseFormat {
  imageurl: string;
}

export interface OnlineTireInventoryClientSideFormat
  extends OnlineTireInventoryBaseFormat {
  imageurl: ImageURLs[];
}

export interface TireConstants {
  tireSizes: Record<number, TireSize>;
  tireBrands: Record<number, string>;
  tireSpeedRatings: Record<number, { value: string; text: string }>;
  tireSeasonRatings: Record<number, string>;
  tirePly: Record<number, string>;
}

export interface TireUIConstants {
  tireSizes: TireConstants["tireSizes"][number][];
  tireBrands: TireConstants["tireBrands"][number][];
  tireSpeedRatings: TireConstants["tireSpeedRatings"][number][];
  tireSeasonRatings: TireConstants["tireSeasonRatings"][number][];
  tirePly: TireConstants["tirePly"][number][];
}

export interface TireSize {
  width: string;
  aspect: string;
  diameter: string;
}
