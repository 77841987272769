import {
  Action,
  ActionReducerMap,
  createFeatureSelector,
  ActionReducer,
  MetaReducer,
} from "@ngrx/store";

import * as fromRouter from "@ngrx/router-store";
import * as fromLayout from "./layout/layout.reducer";
import * as fromAuth from "./pages/auth/auth.reducer";
import * as fromInventory from "./pages/tabs/inventory/inventory.reducer";
import * as fromSignInForm from "./pages/auth/signin/signin.form";
import * as fromTireInventoryForm from "./pages/tabs/inventory/tire-crud/tire-crud.form";
import * as fromTireOnlineEditForm from "./pages/tabs/inventory/tire-online-edit/tire-online-edit.form";

import { InjectionToken } from "@angular/core";
import { RootStoreState } from "./store.models";
import { environment } from "src/environments/environment";

export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<RootStoreState, Action>
>("Root reducers token", {
  factory: () => ({
    [fromLayout.layoutFeatureKey]: fromLayout.reducer,
    [fromAuth.authFeatureKey]: fromAuth.reducer,
    [fromInventory.inventoryFeatureKey]: fromInventory.reducer,
    router: fromRouter.routerReducer,
    [fromSignInForm.signInFormKey]: fromSignInForm.reducer,
    [fromTireInventoryForm.tireCRUDFormKey]: fromTireInventoryForm.reducer,
    [fromTireOnlineEditForm.tireOnlineEditFormKey]:
      fromTireOnlineEditForm.reducer,
  }),
});

// console.log all actions
export function logger(
  reducer: ActionReducer<RootStoreState>
): ActionReducer<RootStoreState> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log("prev state", state);
    console.log("action", action);
    console.log("next state", result);
    console.groupEnd();

    return result;
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<
  RootStoreState
>[] = !environment.production ? [logger] : [];

export const selectLayoutState = createFeatureSelector<
  RootStoreState,
  fromLayout.LayoutStoreState
>(fromLayout.layoutFeatureKey);

export const selectAuthState = createFeatureSelector<
  RootStoreState,
  fromAuth.AuthStoreState
>(fromAuth.authFeatureKey);

export const selecInventoryState = createFeatureSelector<
  RootStoreState,
  fromInventory.InventoryStoreState
>(fromInventory.inventoryFeatureKey);

export const selectRouterState = createFeatureSelector<
  RootStoreState,
  fromRouter.RouterReducerState
>("router");

export const selectSignInFormState = createFeatureSelector<
  RootStoreState,
  RootStoreState[typeof fromSignInForm.signInFormKey]
>(fromSignInForm.signInFormKey);

export const selectTireInventoryFormState = createFeatureSelector<
  RootStoreState,
  RootStoreState[typeof fromTireInventoryForm.tireCRUDFormKey]
>(fromTireInventoryForm.tireCRUDFormKey);

export const selectTireOnlineEditFormState = createFeatureSelector<
  RootStoreState,
  RootStoreState[typeof fromTireOnlineEditForm.tireOnlineEditFormKey]
>(fromTireOnlineEditForm.tireOnlineEditFormKey);
