import { createReducer, on, Action } from "@ngrx/store";
import { IEntity, entityInitialStateFactory } from "src/app/libs";
import produce from "immer";

import * as fromInventory from "./inventory.actions";
import {
  TireConstants,
  TireInventorySearchResults,
  OfflineTireInventoryClientSideFormat,
} from "./inventory.models";
import {
  TireSizes,
  TireBrands,
  TireSpeedRatings,
  TireSeasonRatings,
  TirePly,
} from "./inventory.constants";

export const inventoryFeatureKey = "inventory";
export interface InventoryStoreState {
  tireSearchResults: IEntity<TireInventorySearchResults>; // Search API inventory results
  searchResultSegmentValue: "VERIFIED" | "NON_VERIFIED" | "ALL";
  localInventory: IEntity<OfflineTireInventoryClientSideFormat>; // Offline inventory
  tireConstants: TireConstants;
  lastUniqueID: string;
  apiURIBase: string;
}

const initialState: InventoryStoreState = {
  tireSearchResults: entityInitialStateFactory({ keyName: "id" }),
  searchResultSegmentValue: "ALL",
  localInventory: entityInitialStateFactory({ keyName: "id" }),
  tireConstants: {
    tireSizes: TireSizes,
    tireBrands: TireBrands,
    tireSpeedRatings: TireSpeedRatings,
    tireSeasonRatings: TireSeasonRatings,
    tirePly: TirePly,
  },
  lastUniqueID: null,
  apiURIBase: "https://invoicing.reusedtire.com/",
};

const inventoryReducer = createReducer(
  initialState,
  on(
    fromInventory.UPDATE_TIRE_SEARCH_RESULTS,
    (inventoryStore, { tireSearchResultsSet }) =>
      // Search API currently does not paginate results, hence we are completely replacing the
      // search results set with the new data in this reducer. If there was pagination we would upsert and merge new data for
      // the paginated cases
      produce(inventoryStore, (draft) => {
        draft.tireSearchResults.ids.splice(0);
        delete draft.tireSearchResults.entities;
        draft.tireSearchResults.entities = {};

        if (tireSearchResultsSet.length) {
          tireSearchResultsSet.forEach((item) => {
            const entityID =
              item[inventoryStore.tireSearchResults.options.keyName];
            draft.tireSearchResults.ids.push(entityID);
            draft.tireSearchResults.entities[entityID] = item;
          });
        }
      })
  ),

  on(
    fromInventory.SEARCH_UPDATE_SEGMENT_VALUE,
    (inventoryStore, { segmentValue }) =>
      produce(inventoryStore, (draft) => {
        draft.searchResultSegmentValue = segmentValue;
      })
  ),

  on(fromInventory.CLEAR_TIRE_SEARCH_RESULTS, (inventoryStore, {}) =>
    produce(inventoryStore, (draft) => {
      draft.tireSearchResults.ids.splice(0);
      delete draft.tireSearchResults.entities;
      draft.tireSearchResults.entities = {};
    })
  ),

  on(
    fromInventory.UPDATE_LAST_UNIQUE_ID_IN_STORE,
    (inventoryStore, { lastUniqueID }) =>
      produce(inventoryStore, (draft) => {
        draft.lastUniqueID = lastUniqueID;
      })
  ),

  on(fromInventory.UPDATE_API_URI_BASE, (inventoryStore, { baseURI }) =>
    produce(inventoryStore, (draft) => {
      draft.apiURIBase = baseURI;
    })
  ),

  on(
    fromInventory.POPULATE_LOCAL_INVENTORY_SUCCESS,
    (inventoryStore, { localInventory }) =>
      produce(inventoryStore, (draft) => {
        draft.localInventory.ids.splice(0);
        delete draft.localInventory.entities;
        draft.localInventory.entities = {};

        if (localInventory.length) {
          localInventory.forEach((item) => {
            const entityID =
              item[inventoryStore.localInventory.options.keyName];
            draft.localInventory.ids.push(entityID);
            draft.localInventory.entities[entityID] = item;
          });
        }
      })
  ),
  on(fromInventory.CLEAR_OFFLINE_INVENTORY_FROM_STORE, (inventoryStore, {}) =>
    produce(inventoryStore, (draft) => {
      draft.localInventory.ids.splice(0);
      delete draft.localInventory.entities;
      draft.localInventory.entities = {};
    })
  )
);

export function reducer(state: InventoryStoreState, action: Action) {
  return inventoryReducer(state, action);
}
