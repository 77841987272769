import {
  createFormGroupState,
  updateGroup,
  validate,
  onNgrxForms,
  wrapReducerWithFormStateUpdate,
  setValue,
  reset,
} from "ngrx-forms";

import { required } from "ngrx-forms/validation";
import { createReducer, on } from "@ngrx/store";
import {
  OnlineTireInventoryClientSideFormat,
  OnlineTireInventoryServerSideFormat,
  DEFAULT_IMAGE_URL,
} from "../inventory.models";

import * as fromTireOnlineEditForm from "./tire-online-edit.actions";
import { AugmentedBOOLEANString, AugmentedBOOLEAN } from "../../../../libs";
import produce from "immer";

export const tireOnlineEditFormKey = "tireOnlineEditForm";

const tireOnlineEditFormInitialState: OnlineTireInventoryClientSideFormat = {
  brand: "",
  brandname: "",
  diameter: "",
  imageurl: Array(5).fill(
    {
      webviewFriendlyPath: DEFAULT_IMAGE_URL,
      filesystemFriendlyPath: "",
    },
    0,
    5
  ),
  lighttruck: AugmentedBOOLEAN.FALSE,
  loadindex: "",
  location: "",
  model: "",
  notes: "",
  price: "",
  quantity: "",
  rate: "",
  ratio: "",
  remaining_tred: "",
  rotational: AugmentedBOOLEAN.FALSE,
  season: "",
  tireply: "",
  width: "",
  tire_id: "",
  user_id: "",
  is_verified: AugmentedBOOLEAN.TRUE,
};

const validateTireCRUDForm = updateGroup<OnlineTireInventoryClientSideFormat>({
  price: validate(required),
  quantity: validate(required),
  brand: validate(required),
  location: validate(required),
  season: validate(required),
  remaining_tred: validate(required),
  width: validate(required),
  ratio: validate(required),
  diameter: validate(required),
});

const rawReducer = createReducer(
  createFormGroupState(tireOnlineEditFormKey, tireOnlineEditFormInitialState),
  onNgrxForms(),
  on(
    fromTireOnlineEditForm.POPULATE_ONLINE_TIRE_INFO,
    (tireCRUDFormState, { tireInfo }) =>
      setValue(
        tireCRUDFormState,

        produce(
          {
            ...tireInfo,
            imageurl: [],
          } as OnlineTireInventoryClientSideFormat,
          (draft) => {
            const existingImages = tireInfo.imageurl
              .split(";")
              .filter((url) => url !== "N/A");
            existingImages.forEach((url) => {
              draft.imageurl.push({
                webviewFriendlyPath: url,
                filesystemFriendlyPath: "",
              });
            });

            if (existingImages.length < 5) {
              draft.imageurl.length = 5;
              draft.imageurl.fill(
                {
                  webviewFriendlyPath: DEFAULT_IMAGE_URL,
                  filesystemFriendlyPath: "",
                },
                existingImages.length,
                5
              );
            }
          }
        )
      )
  ),
  on(
    fromTireOnlineEditForm.RESET_TIRE_ONLINE_EDIT_FORM,
    (tireCRUDFormState, {}) => {
      const formWithInitialValues = setValue(tireCRUDFormState, {
        ...tireOnlineEditFormInitialState,
      });

      return reset(formWithInitialValues);
    }
  ),
  on(
    fromTireOnlineEditForm.PATCH_FORM_VALUES,
    (tireCRUDFormState, { tireInfoPartial }) =>
      setValue(
        tireCRUDFormState,
        produce(tireCRUDFormState.value, (draft) => {
          Object.keys(tireInfoPartial).forEach((key) => {
            draft[key] = tireInfoPartial[key];
          });
        })
      )
  )
);

// wrapReducerWithFormStateUpdate calls the update function
// after the given reducer; you can wrap this reducer again
// if you have multiple forms in your state
export const reducer = wrapReducerWithFormStateUpdate(
  rawReducer,
  // point to the form state to update
  (s) => s,
  // this function is always called after the reducer
  validateTireCRUDForm
);
