import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import * as fromLayout from "../../../layout/layout.actions";
import * as fromInventory from "./inventory.actions";

import { of, from, throwError } from "rxjs";
import {
  switchMap,
  catchError,
  withLatestFrom,
  map,
  concatMap,
} from "rxjs/operators";
import { HttpParams, HttpClient } from "@angular/common/http";
import { Store, select } from "@ngrx/store";
import { selectLoggedInUser } from "../../auth/auth.selectors";
import {
  SearchAPIResponse,
  FetchLastUniqueIDAPIResponse,
  DATABASE_INVENTORY_IDS_LOCATION,
  DATABASE_LAST_UNIQUEID_LOCATION,
  OfflineTireInventoryClientSideFormat,
  OfflineTireInventoryServerSideFormat,
  BulkInsertNewInventoryResponse,
  DEFAULT_IMAGE_URL,
  OnlineTireInventoryServerSideFormat,
  SetTireResponse,
  DeleteOnlineInventoryAPIResponse,
  MoveOnlineInventoryResponse,
} from "./inventory.models";
import { selectLastUniqueID, selectApiURIBase } from "./inventory.selectors";
import { Storage } from "@ionic/storage";
import { DateTime } from "luxon";
import produce from "immer";
import {
  FileTransfer,
  FileTransferObject,
  FileUploadOptions,
  FileUploadResult,
} from "@ionic-native/file-transfer/ngx";
@Injectable()
export class InventoryEffects {
  fetchLastUniqueID$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromInventory.FETCH_LAST_UNIQUE_ID),
        withLatestFrom(this.store.pipe(select(selectApiURIBase))),
        switchMap(([_, URIBase]) => {
          const params = new HttpParams().set("type", "getLastID");
          return this.http
            .get<FetchLastUniqueIDAPIResponse>(
              URIBase + "api/api2015-06-07.php",
              {
                params,
              }
            )
            .pipe(
              catchError(() => {
                // ToDo: API does not return a specific error message
                return of("ERROR");
              })
            );
        }),
        switchMap((response) => {
          return typeof response !== "string"
            ? // Success Response, lastUniqueID recieved

              this.storage
                .get(DATABASE_LAST_UNIQUEID_LOCATION)
                .then((localLastUniqueID: string) => {
                  if (Number(response.last_id) > Number(localLastUniqueID)) {
                    return this.storage
                      .set(DATABASE_LAST_UNIQUEID_LOCATION, response.last_id)
                      .then(() =>
                        fromInventory.UPDATE_LAST_UNIQUE_ID_IN_STORE({
                          lastUniqueID: response.last_id,
                        })
                      );
                  }

                  return fromInventory.UPDATE_LAST_UNIQUE_ID_IN_STORE({
                    lastUniqueID: localLastUniqueID,
                  });
                })
            : // Error Response

              of(
                fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                  alertComponentOptions: {
                    title: "Warning",
                    message:
                      "Failed to obtain the Unique ID sequence from server. The application will operate in offline mode. If you are connected to the internet, please restart the application to retry",
                    buttons: {
                      cancel: {
                        buttonText: "OK",
                        buttonAction: async () => {
                          // no-op
                        },
                      },
                      confirm: null,
                    },
                  },
                })
              );
        })
      ),
    {
      dispatch: true,
      useEffectsErrorHandler: true,
    }
  );

  search$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromInventory.SEARCH_TIRE_INVENTORY),
        withLatestFrom(
          this.store.pipe(select(selectLoggedInUser)),
          this.store.pipe(select(selectApiURIBase))
        ),

        switchMap(([{ paramProps }, user, URIBase]) => {
          let params = new HttpParams().set("type", paramProps.type);
          params = params.set("user_id", user.user_id.toString()); // HttpParams is immutable
          params = params.set("keyword", paramProps.keyword);
          return this.http
            .get<SearchAPIResponse>(URIBase + "api/api2015-06-07.php", {
              params,
            })
            .pipe(
              // Currently, the PHP Api returns all results in database when there is a partial match on the ID number
              // Search with keyword of '1' will return 100% of the inventory in the server since all tires have ID starting with '1'
              // The retuned results are fully detailed results and hence contain all the inventory information
              // The payload wil be too large and displaying it will crash the UI when there are images missing, etc..
              // This should be fixed at the Search API level in the future, and have paginated, well matched results sent downstream.
              // As a temporary solution to not crash the UI, a clientside fix for now is to only display the first 10 results

              catchError(() => {
                // ToDo: API does not return a specific error message
                return of("ERROR");
              }),
              map((response) => {
                if (typeof response === "string") {
                  return "ERROR"; // Propagate error from the previous catchError block
                }

                console.log(response);

                return produce(response, (draft) => {
                  draft.data.splice(20);
                });
              })
            );
        }),
        switchMap((response) => {
          return typeof response !== "string"
            ? // Success Response, search results recieved

              response.data.length
              ? of(
                  fromInventory.UPDATE_TIRE_SEARCH_RESULTS({
                    tireSearchResultsSet: response.data,
                  })
                )
              : // Success Response, empty results recieved

                [
                  fromInventory.UPDATE_TIRE_SEARCH_RESULTS({
                    tireSearchResultsSet: [],
                  }),
                  fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                    alertComponentOptions: {
                      title: "No Search Results",
                      message: "Please search again with a different keyword",
                      buttons: {
                        cancel: {
                          buttonText: "OK",
                          buttonAction: async () => {
                            // no-op
                          },
                        },
                        confirm: null,
                      },
                    },
                  }),
                ]
            : // Error Response

              of(
                fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                  alertComponentOptions: {
                    title: "Error: Search API",
                    message:
                      "Please contact technical support if the problem persists",
                    buttons: {
                      cancel: {
                        buttonText: "OK",
                        buttonAction: async () => {
                          // no-op
                        },
                      },
                      confirm: null,
                    },
                  },
                })
              );
        })
      ),
    {
      dispatch: true,
      useEffectsErrorHandler: true,
    }
  );

  populateLocalInventory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromInventory.POPULATE_LOCAL_INVENTORY),
        switchMap(() =>
          this.storage
            .get(DATABASE_INVENTORY_IDS_LOCATION)
            .then((inventoryIDsList: string[]) =>
              inventoryIDsList ? inventoryIDsList : []
            )
            .then((inventoryIDsList: string[]) =>
              Promise.all(
                inventoryIDsList.map(
                  (inventoryID) =>
                    this.storage.get(
                      inventoryID
                    ) as Promise<OfflineTireInventoryClientSideFormat>
                )
              )
            )
        ),
        catchError((err) => {
          console.error(err);
          return of("ERROR");
        }),
        switchMap((response) => {
          return typeof response !== "string"
            ? // Success Response, search results recieved

              of(
                fromInventory.POPULATE_LOCAL_INVENTORY_SUCCESS({
                  localInventory: response,
                })
              )
            : // Error Response

              of(
                fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                  alertComponentOptions: {
                    title: "Error: Local read error",
                    message: "Could not fetch your local inventories",
                    buttons: {
                      cancel: {
                        buttonText: "OK",
                        buttonAction: async () => {
                          // no-op
                        },
                      },
                      confirm: null,
                    },
                  },
                })
              );
        })
      ),
    {
      dispatch: true,
      useEffectsErrorHandler: true,
    }
  );

  clearLocalDatabase$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromInventory.CLEAR_OFFLINE_INVENTORY_FROM_LOCAL_DB),
        switchMap(() =>
          this.storage
            .get(DATABASE_INVENTORY_IDS_LOCATION)
            .then((inventoryIDsList: string[]) =>
              inventoryIDsList ? inventoryIDsList : []
            )
            .then((inventoryIDsList: string[]) =>
              Promise.all(
                inventoryIDsList.map((inventoryID) =>
                  this.storage.remove(inventoryID)
                )
              )
            )
            .then(() => this.storage.set(DATABASE_INVENTORY_IDS_LOCATION, []))
        ),
        catchError((err) => {
          console.error(err);
          return of("ERROR");
        }),
        switchMap((response) => {
          return typeof response !== "string"
            ? // Success Response, search results recieved

              of(fromInventory.CLEAR_OFFLINE_INVENTORY_FROM_STORE())
            : // Error Response

              of(
                fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                  alertComponentOptions: {
                    title: "Error: Local write error",
                    message: "Could not remove offline inventories",
                    buttons: {
                      cancel: {
                        buttonText: "OK",
                        buttonAction: async () => {
                          // no-op
                        },
                      },
                      confirm: null,
                    },
                  },
                })
              );
        })
      ),
    {
      dispatch: true,
      useEffectsErrorHandler: true,
    }
  );

  createLocalInventory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromInventory.ADD_TIRE_TO_LOCAL_INVENTORY),
        withLatestFrom(
          this.store.pipe(select(selectLoggedInUser)),
          this.store.pipe(select(selectLastUniqueID))
        ),
        switchMap(([{ tireInfo }, user, lastUniqueID]) => {
          const nextUniqueID = (Number(lastUniqueID) + 1).toString();
          let isLocalEdit = true;
          const newInventory = produce(tireInfo, (draft) => {
            if (!draft.id) {
              isLocalEdit = false;
              draft.id = Number(nextUniqueID);
            }

            draft.user_id = user.user_id;
            draft.username = user.email;
            draft.unique = DateTime.utc().toMillis().toString();
            draft.price = Number(tireInfo.price); // ionic forms set it as string
          });

          const saveInventoryLocally = this.storage.set(
            newInventory.id.toString(),
            newInventory
          );

          const updateInventoryIDsList = this.storage
            .get(DATABASE_INVENTORY_IDS_LOCATION)
            .then((inventoryIDsList: string[]) =>
              inventoryIDsList ? inventoryIDsList : []
            )
            .then((inventoryIDsList: string[]) =>
              produce(inventoryIDsList, (draft) => {
                if (!inventoryIDsList.includes(newInventory.id.toString())) {
                  draft.push(newInventory.id.toString());
                }
              })
            )
            .then((updatedInventoryIDsList) =>
              this.storage.set(
                DATABASE_INVENTORY_IDS_LOCATION,
                updatedInventoryIDsList
              )
            );

          const updateLastUniqueID = isLocalEdit
            ? Promise.resolve(lastUniqueID)
            : this.storage
                .set(DATABASE_LAST_UNIQUEID_LOCATION, nextUniqueID)
                .then(() => nextUniqueID);

          return Promise.all([
            updateLastUniqueID,
            saveInventoryLocally,
            updateInventoryIDsList,
          ]);
        }),
        catchError((err) => {
          console.error(err);
          return of("ERROR");
        }),
        switchMap((response) => {
          return typeof response !== "string"
            ? // Success Response,
              // response looks like this -> [updatedLastUniqueID,_,_]

              [
                fromInventory.UPDATE_LAST_UNIQUE_ID_IN_STORE({
                  lastUniqueID: response[0],
                }),
                fromInventory.UPLOAD_LOCAL_INVENTORY_TO_SERVER(),
              ]
            : // Error

              [
                fromInventory.UPDATE_LAST_UNIQUE_ID_IN_STORE({
                  lastUniqueID: response[0],
                }),
                fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                  alertComponentOptions: {
                    title: "Error: Could not save inventory",
                    message:
                      "Could not save the inventory at this time, please try again",
                    buttons: {
                      cancel: {
                        buttonText: "OK",
                        buttonAction: async () => {
                          // no-op
                        },
                      },
                      confirm: null,
                    },
                  },
                }),
              ];
        })
      ),
    {
      dispatch: true,
      useEffectsErrorHandler: true,
    }
  );

  batchUploadLocalInventory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromInventory.UPLOAD_LOCAL_INVENTORY_TO_SERVER),
        switchMap(() =>
          this.storage
            .get(DATABASE_INVENTORY_IDS_LOCATION)
            .then((inventoryIDsList: string[]) =>
              inventoryIDsList ? inventoryIDsList : []
            )
            .then((inventoryIDsList) =>
              Promise.all(
                inventoryIDsList.map(
                  (inventoryID) =>
                    this.storage.get(
                      inventoryID
                    ) as Promise<OfflineTireInventoryClientSideFormat>
                )
              )
            )
        ),
        switchMap((localInventories) =>
          from(localInventories).pipe(
            concatMap((localInventory) => {
              console.log(localInventory);
              let inventoryImageURLs = "";
              const newImagesToUpload = localInventory.imageurl.filter(
                (url) => url.filesystemFriendlyPath !== ""
              );
              let allImagesForThisInventoryIsUploaded = false;

              // Inventory Image Upload Start
              return from(
                newImagesToUpload.length ? newImagesToUpload : [null]
              ).pipe(
                withLatestFrom(this.store.pipe(select(selectApiURIBase))),
                concatMap(([urls, URIBase], currentUploadingImageIndex) => {
                  allImagesForThisInventoryIsUploaded =
                    currentUploadingImageIndex === newImagesToUpload.length - 1;

                  let uploadImage: Promise<string | FileUploadResult | {}>;

                  if (!urls) {
                    inventoryImageURLs = "N/A";
                    allImagesForThisInventoryIsUploaded = true;
                    console.log(
                      "No images to upload, proceed without file transfer"
                    );
                    uploadImage = Promise.resolve({});
                  } else {
                    const fileTransfer: FileTransferObject =
                      this.transfer.create();
                    const filename = `${DateTime.utc().toMillis()}.jpg`;
                    const fileTransferOptions: FileUploadOptions = {
                      fileKey: "file",
                      fileName: filename,
                      mimeType: "image/jpg",
                      httpMethod: "POST",
                      chunkedMode: false,
                    };

                    uploadImage = fileTransfer
                      .upload(
                        urls.filesystemFriendlyPath,
                        encodeURI(URIBase + "api/uploadFiles.php"),
                        fileTransferOptions
                      )
                      .then((result) => {
                        console.log("A file was  transferred successfully: ");
                        console.log(result);
                        inventoryImageURLs = produce(
                          inventoryImageURLs.split(";"),
                          (draft) => {
                            draft.push(`${URIBase}upload/${filename}`);
                          }
                        ).join(";");

                        if (inventoryImageURLs.startsWith(";")) {
                          inventoryImageURLs = inventoryImageURLs.substring(1);
                        }

                        console.log(
                          "updated inventoryImageURLs string: " +
                            inventoryImageURLs
                        );
                        return result;
                      })
                      .catch((error) => {
                        console.error(
                          "A file was not transferred successfully: "
                        );
                        console.error(error);
                        return "IMAGE UPLOAD ERROR";
                      });
                  }

                  return from(uploadImage).pipe(
                    switchMap((fileUploadResult) => {
                      console.log(fileUploadResult);
                      if (typeof fileUploadResult !== "string") {
                        console.log(
                          "There were no image file uploads, or no image file upload related errors"
                        );
                        if (allImagesForThisInventoryIsUploaded) {
                          console.log(
                            "All image files have been uploaded, or there were no image file uploads "
                          );
                          // Inventory Image Upload Finish
                          // Upload the inventory now

                          const formData = new FormData();
                          formData.append(
                            "data",
                            JSON.stringify([
                              produce(
                                {
                                  ...localInventory,
                                  imageurl: null,
                                } as OfflineTireInventoryServerSideFormat,
                                (draft) => {
                                  draft.imageurl = inventoryImageURLs;
                                }
                              ),
                            ])
                          );

                          return this.http
                            .post<BulkInsertNewInventoryResponse>(
                              URIBase + "api/api2015-06-07.php",
                              formData,
                              {
                                params: new HttpParams().set(
                                  "type",
                                  "registration"
                                ),
                              }
                            )
                            .pipe(
                              catchError((error) => {
                                console.log(
                                  "HTTP POST error, Please connect to the internet and try again: "
                                );
                                console.error(error);
                                return of("ERROR");
                              }),
                              switchMap((response) => {
                                console.log(response);

                                if (typeof response === "string") {
                                  return [
                                    fromInventory.POPULATE_LOCAL_INVENTORY(),
                                    fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                                      alertComponentOptions: {
                                        title:
                                          "Warning: Inventory Upload Failed",
                                        message:
                                          "Could not upload some locally saved inventories to server." +
                                          " They are however still available locally on your device",
                                        buttons: {
                                          cancel: {
                                            buttonText: "OK",
                                            buttonAction: async () => {
                                              // no-op
                                            },
                                          },
                                          confirm: null,
                                        },
                                      },
                                    }),
                                  ];
                                }

                                if (response[0].result === "SUCCESS") {
                                  // Inventory Upload Success
                                  console.log("Inventory upload success:");
                                  console.log(response[0]);

                                  return of(response[0]).pipe(
                                    switchMap(
                                      (inventoryUploadSuccessResponse) => {
                                        const deleteInventoryLocally =
                                          this.storage.remove(
                                            inventoryUploadSuccessResponse.id.toString()
                                          );
                                        const updateInventoryIDsList = (
                                          this.storage.get(
                                            DATABASE_INVENTORY_IDS_LOCATION
                                          ) as Promise<string[]>
                                        )
                                          .then((inventoryIDsList) =>
                                            produce(
                                              inventoryIDsList
                                                ? inventoryIDsList
                                                : [],
                                              (draft) => {
                                                const index = draft.findIndex(
                                                  (id) =>
                                                    id ===
                                                    inventoryUploadSuccessResponse.id.toString()
                                                );
                                                if (index !== -1) {
                                                  draft.splice(index, 1);
                                                }
                                              }
                                            )
                                          )
                                          .then((updatedInventoryIDsList) =>
                                            this.storage.set(
                                              DATABASE_INVENTORY_IDS_LOCATION,
                                              updatedInventoryIDsList
                                            )
                                          );

                                        return from(
                                          Promise.all([
                                            deleteInventoryLocally,
                                            updateInventoryIDsList,
                                          ])
                                        ).pipe(
                                          switchMap(() => [
                                            fromInventory.POPULATE_LOCAL_INVENTORY(),
                                            fromInventory.SEARCH_TIRE_INVENTORY(
                                              {
                                                paramProps: {
                                                  type: "searchtire",
                                                  keyword: "1",
                                                },
                                              }
                                            ),
                                            fromLayout.LAYOUT_PRESENT_AUTO_DISMISSING_TOAST_COMPONENT(
                                              {
                                                autoDismissingToastComponentOptions:
                                                  {
                                                    message:
                                                      "An inventory was succesfully uploaded to server!",
                                                    duration: 2500,
                                                  },
                                              }
                                            ),
                                          ])
                                        );
                                      }
                                    )
                                  );
                                } else {
                                  // Inventory Upload error

                                  console.error("Inventory upload error!");

                                  return [
                                    fromInventory.POPULATE_LOCAL_INVENTORY(),
                                    fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                                      alertComponentOptions: {
                                        title:
                                          "Warning: Inventory Upload Failed",
                                        message:
                                          "Could not upload some locally saved inventories to server." +
                                          " They are however still available locally on your device",
                                        buttons: {
                                          cancel: {
                                            buttonText: "OK",
                                            buttonAction: async () => {
                                              // no-op
                                            },
                                          },
                                          confirm: null,
                                        },
                                      },
                                    }),
                                  ];
                                }
                              })
                            );
                        } else {
                          // More images are still being uploaded

                          console.log("More images are being uploaded...");
                          return [
                            fromLayout.LAYOUT_PRESENT_AUTO_DISMISSING_TOAST_COMPONENT(
                              {
                                autoDismissingToastComponentOptions: {
                                  message:
                                    "Inventory image upload in progress...",
                                  duration: 2500,
                                },
                              }
                            ),
                            fromInventory.POPULATE_LOCAL_INVENTORY(),
                          ];
                        }
                      } else {
                        // Image upload error

                        console.error("Image upload error!!");

                        return [
                          fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                            alertComponentOptions: {
                              title: "ERROR: Inventory Upload Haulted",
                              message:
                                "Inventory data was not uploaded to the server because some images failed to upload." +
                                " They are however still available locally on your device",
                              buttons: {
                                cancel: {
                                  buttonText: "OK",
                                  buttonAction: async () => {
                                    // no-op
                                  },
                                },
                                confirm: null,
                              },
                            },
                          }),
                          fromInventory.POPULATE_LOCAL_INVENTORY(),
                        ];
                      }
                    }),
                    catchError((err) => {
                      console.error(err);

                      return from([
                        fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                          alertComponentOptions: {
                            title: "ERROR: Inventory Upload Failed",
                            message:
                              "Inventory data was not uploaded to the server " +
                              " They are however still available locally on your device",
                            buttons: {
                              cancel: {
                                buttonText: "OK",
                                buttonAction: async () => {
                                  // no-op
                                },
                              },
                              confirm: null,
                            },
                          },
                        }),
                        fromInventory.POPULATE_LOCAL_INVENTORY(),
                      ]);
                    })
                  );
                })
              );
            })
          )
        )
      ),
    {
      dispatch: true,
      useEffectsErrorHandler: true,
    }
  );

  updateOnlineInventory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromInventory.ONLINE_INVENTORY_SET_TIRE),
        withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
        switchMap(([{ tireInfo }, user]) => {
          console.log(tireInfo);
          let inventoryImageURLs = "";
          const newImagesToUpload = tireInfo.imageurl.filter(
            (url) => url.filesystemFriendlyPath !== ""
          );
          let allImagesForThisInventoryIsUploaded = false;

          // Inventory Image Upload Start
          return from(
            newImagesToUpload.length ? newImagesToUpload : [null]
          ).pipe(
            withLatestFrom(this.store.pipe(select(selectApiURIBase))),
            concatMap(([urls, URIBase], currentUploadingImageIndex) => {
              allImagesForThisInventoryIsUploaded =
                currentUploadingImageIndex === newImagesToUpload.length - 1;

              let uploadImage: Promise<string | FileUploadResult | {}>;

              if (!urls) {
                inventoryImageURLs = "N/A";
                allImagesForThisInventoryIsUploaded = true;
                console.log(
                  "No images to upload, proceed without file transfer"
                );
                uploadImage = Promise.resolve({});
              } else {
                const fileTransfer: FileTransferObject = this.transfer.create();
                const filename = `${DateTime.utc().toMillis()}.jpg`;
                const fileTransferOptions: FileUploadOptions = {
                  fileKey: "file",
                  fileName: filename,
                  mimeType: "image/jpg",
                  httpMethod: "POST",
                  chunkedMode: false,
                };

                uploadImage = fileTransfer
                  .upload(
                    urls.filesystemFriendlyPath,
                    encodeURI(URIBase + "api/uploadFiles.php"),
                    fileTransferOptions
                  )
                  .then((result) => {
                    console.log("A file was  transferred successfully: ");
                    console.log(result);
                    inventoryImageURLs = produce(
                      inventoryImageURLs.split(";"),
                      (draft) => {
                        draft.push(`${URIBase}upload/${filename}`);
                      }
                    ).join(";");

                    if (inventoryImageURLs.startsWith(";")) {
                      inventoryImageURLs = inventoryImageURLs.substring(1);
                    }

                    console.log(
                      "updated inventoryImageURLs string: " + inventoryImageURLs
                    );
                    return result;
                  })
                  .catch((error) => {
                    console.error("A file was not transferred successfully: ");
                    console.error(error);
                    return "IMAGE UPLOAD ERROR";
                  });
              }

              return from(uploadImage).pipe(
                switchMap((fileUploadResult) => {
                  console.log(fileUploadResult);
                  if (typeof fileUploadResult !== "string") {
                    console.log(
                      "There were no image file uploads, or no image file upload related errors"
                    );
                    if (allImagesForThisInventoryIsUploaded) {
                      console.log(
                        "All image files have been uploaded, or there were no image file uploads "
                      );
                      // Inventory Image Upload Finish
                      // Update the inventory now

                      const existingServerImageURls = tireInfo.imageurl
                        .filter(
                          (url) =>
                            url.filesystemFriendlyPath === "" &&
                            url.webviewFriendlyPath !== DEFAULT_IMAGE_URL
                        )
                        .map((url) => url.webviewFriendlyPath)
                        .join(";");

                      if (
                        !existingServerImageURls.startsWith(";") &&
                        existingServerImageURls != ""
                      ) {
                        inventoryImageURLs = `${existingServerImageURls};${inventoryImageURLs}`;
                      }

                      console.log("");
                      console.log("Final imageurl: " + inventoryImageURLs);

                      const formData = new FormData();
                      formData.append(
                        "data",
                        JSON.stringify(
                          produce(
                            {
                              ...tireInfo,
                              imageurl: null,
                            } as OnlineTireInventoryServerSideFormat,
                            (draft) => {
                              draft.imageurl = inventoryImageURLs;
                            }
                          )
                        )
                      );

                      console.log(formData);

                      let params = new HttpParams();
                      // HttpParams is immutable
                      params = params.set("type", "settire");
                      params = params.set("user_id", user.user_id.toString());

                      return this.http
                        .post<SetTireResponse>(
                          URIBase + "api/api2015-06-07.php",
                          formData,
                          { params }
                        )
                        .pipe(
                          catchError((error) => {
                            console.log(
                              "HTTP POST error, Please connect to the internet and try again: "
                            );
                            console.error(error);
                            return of("ERROR");
                          }),
                          switchMap((response) => {
                            console.log(response);

                            if (typeof response === "string") {
                              return of(
                                fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                                  alertComponentOptions: {
                                    title: "Error: Inventory Update Failed",
                                    message:
                                      "Could not inventory in the server.",
                                    buttons: {
                                      cancel: {
                                        buttonText: "OK",
                                        buttonAction: async () => {
                                          // no-op
                                        },
                                      },
                                      confirm: null,
                                    },
                                  },
                                })
                              );
                            }

                            if (response.result === "SUCCESS") {
                              // Inventory Upload Success
                              console.log("Inventory upload success:");
                              console.log(response);

                              return [
                                fromLayout.LAYOUT_PRESENT_AUTO_DISMISSING_TOAST_COMPONENT(
                                  {
                                    autoDismissingToastComponentOptions: {
                                      message:
                                        "inventory was succesfully updated!",
                                      duration: 2500,
                                    },
                                  }
                                ),
                                fromInventory.SEARCH_TIRE_INVENTORY({
                                  paramProps: {
                                    keyword: tireInfo.tire_id || "1",
                                    type: "searchtire",
                                  },
                                }),
                              ];
                            } else {
                              // Inventory Upload error

                              console.error("Inventory upload error!");

                              return of(
                                fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                                  alertComponentOptions: {
                                    title: "Error: Inventory Update Failed",
                                    message:
                                      "Could not update inventory in the server.",
                                    buttons: {
                                      cancel: {
                                        buttonText: "OK",
                                        buttonAction: async () => {
                                          // no-op
                                        },
                                      },
                                      confirm: null,
                                    },
                                  },
                                })
                              );
                            }
                          })
                        );
                    } else {
                      // More images are still being uploaded

                      console.log("More images are being uploaded...");
                      return of(
                        fromLayout.LAYOUT_PRESENT_AUTO_DISMISSING_TOAST_COMPONENT(
                          {
                            autoDismissingToastComponentOptions: {
                              message: "Inventory image upload in progress...",
                              duration: 2500,
                            },
                          }
                        )
                      );
                    }
                  } else {
                    // Image upload error

                    console.error("Image upload error!!");

                    return of(
                      fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                        alertComponentOptions: {
                          title: "ERROR: Inventory Update Failed",
                          message:
                            "Inventory data was not uploaded to the server because some images failed to upload.",
                          buttons: {
                            cancel: {
                              buttonText: "OK",
                              buttonAction: async () => {
                                // no-op
                              },
                            },
                            confirm: null,
                          },
                        },
                      })
                    );
                  }
                }),
                catchError((err) => {
                  console.error(err);

                  return of(
                    fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                      alertComponentOptions: {
                        title: "ERROR: Inventory Upload Failed",
                        message:
                          "Inventory data was not updated in the server ",
                        buttons: {
                          cancel: {
                            buttonText: "OK",
                            buttonAction: async () => {
                              // no-op
                            },
                          },
                          confirm: null,
                        },
                      },
                    })
                  );
                })
              );
            })
          );
        })
      ),
    {
      dispatch: true,
      useEffectsErrorHandler: true,
    }
  );

  deleteOnlineInventory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromInventory.ONLINE_INVENTORY_DELETE_TIRE),
        withLatestFrom(
          this.store.pipe(select(selectLoggedInUser)),
          this.store.pipe(select(selectApiURIBase))
        ),

        switchMap(([{ tireID, userID }, user, URIBase]) => {
          if (userID != user.user_id.toString()) {
            throwError(of("ERROR"));
          }

          let params = new HttpParams().set("type", "deletetire");
          params = params.set("user_id", user.user_id.toString()); // HttpParams is immutable
          params = params.set("tire_id", tireID);
          return this.http
            .get<DeleteOnlineInventoryAPIResponse>(
              URIBase + "api/api2015-06-07.php",
              {
                params,
              }
            )
            .pipe(
              catchError(() => {
                // ToDo: API does not return a specific error message
                return of("ERROR");
              })
            );
        }),
        switchMap((response) => {
          console.log(response);
          return typeof response !== "string"
            ? // Success Response, search results recieved

              [
                fromLayout.LAYOUT_PRESENT_AUTO_DISMISSING_TOAST_COMPONENT({
                  autoDismissingToastComponentOptions: {
                    message: "Inventory was successfully deleted",
                    duration: 3500,
                  },
                }),
                fromInventory.CLEAR_TIRE_SEARCH_RESULTS(),
              ]
            : // Error Response

              of(
                fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                  alertComponentOptions: {
                    title: "Error: Could not delete online inventory",
                    message:
                      "Do you have sufficient permissions to delete? Please contact technical support if the problem persists",
                    buttons: {
                      cancel: {
                        buttonText: "OK",
                        buttonAction: async () => {
                          // no-op
                        },
                      },
                      confirm: null,
                    },
                  },
                })
              );
        })
      ),
    {
      dispatch: true,
      useEffectsErrorHandler: true,
    }
  );

  moveInventoryOnline$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromInventory.ONLINE_MOVE_INVENTORY),
        withLatestFrom(
          this.store.pipe(select(selectLoggedInUser)),
          this.store.pipe(select(selectApiURIBase))
        ),
        switchMap(([{ tire_id, location }, user, URIBase]) => {
          return this.http
            .get<MoveOnlineInventoryResponse>(
              URIBase + "api/api2015-06-07.php",
              {
                params: {
                  type: "movetire",
                  user_id: user.user_id.toString(),
                  "tire_id[]": tire_id,
                  location,
                },
              }
            )
            .pipe(
              catchError(() => {
                // ToDo: API does not return a specific error message
                return of("ERROR");
              })
            );
        }),
        switchMap((response) => {
          console.log(response);
          return typeof response !== "string"
            ? // Success Response, search results recieved

              [
                fromLayout.LAYOUT_PRESENT_AUTO_DISMISSING_TOAST_COMPONENT({
                  autoDismissingToastComponentOptions: {
                    message: "Inventory was successfully moved!",
                    duration: 3500,
                  },
                }),
                fromInventory.CLEAR_TIRE_SEARCH_RESULTS(),
              ]
            : // Error Response

              of(
                fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
                  alertComponentOptions: {
                    title: "Error: Could not move inventory",
                    message:
                      "Do you have sufficient permissions to delete? Please check your network connection",
                    buttons: {
                      cancel: {
                        buttonText: "OK",
                        buttonAction: async () => {
                          // no-op
                        },
                      },
                      confirm: null,
                    },
                  },
                })
              );
        })
      ),
    {
      dispatch: true,
      useEffectsErrorHandler: true,
    }
  );

  constructor(
    private actions$: Actions,
    private storage: Storage,
    private store: Store,
    private http: HttpClient,
    private transfer: FileTransfer
  ) {}
}
