import {
  createFormGroupState,
  updateGroup,
  validate,
  onNgrxForms,
  wrapReducerWithFormStateUpdate,
} from "ngrx-forms";

import { email, required } from "ngrx-forms/validation";
import { createReducer } from "@ngrx/store";
import { SignInForm } from "../auth.models";

export const signInFormKey = "signInForm";

const signInFormInitialState: SignInForm = {
  email: "",
  password: "",
};

const validateSignInForm = updateGroup<SignInForm>({
  email: validate(email, required),
  password: validate(required),
});

const rawReducer = createReducer(
  createFormGroupState(signInFormKey, signInFormInitialState),
  onNgrxForms()
);

// wrapReducerWithFormStateUpdate calls the update function
// after the given reducer; you can wrap this reducer again
// if you have multiple forms in your state
export const reducer = wrapReducerWithFormStateUpdate(
  rawReducer,
  // point to the form state to update
  (s) => s,
  // this function is always called after the reducer
  validateSignInForm
);
