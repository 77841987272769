// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDprQi4kgJANiW9QabsyME_xJNYeaHbe6E",
    authDomain: "reusedtirev2.firebaseapp.com",
    projectId: "reusedtirev2",
    storageBucket: "reusedtirev2.appspot.com",
    messagingSenderId: "935414520002",
    appId: "1:935414520002:web:c778b5493bcc58e51cc35b",
    measurementId: "G-9BGTP2T65H",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import "zone.js/dist/zone-error"; // Included with Angular CLI.
