import { selectRouterState, selectLayoutState } from "../root.store";
import { createSelector } from "@ngrx/store";

import * as fromRouter from "@ngrx/router-store";
import * as fromLayout from "./layout.reducer";

export const selectTabPages = createSelector(
  selectLayoutState,
  (state: fromLayout.LayoutStoreState) => state.tabPages
);

export const selectSplitpaneIsVisible = createSelector(
  selectLayoutState,
  (state: fromLayout.LayoutStoreState) => state.splitpaneIsVisible
);

export const selectSplitpaneIsEnabled = createSelector(
  selectLayoutState,
  (state: fromLayout.LayoutStoreState) => state.splitpaneIsEnabled
);

export const selectDeviceInfo = createSelector(
  selectLayoutState,
  (state: fromLayout.LayoutStoreState) => state.deviceInfo
);

export const selectAlertComponentOptions = createSelector(
  selectLayoutState,
  (state: fromLayout.LayoutStoreState) => state.alertComponentOptions
);

export const {
  selectCurrentRoute, // select the current route
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = fromRouter.getSelectors(selectRouterState);
