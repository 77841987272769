import { NgModule } from "@angular/core";

import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import {
  StoreRouterConnectingModule,
  RouterState,
  NavigationActionTiming,
} from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { NgrxFormsModule } from "ngrx-forms";

import { ROOT_REDUCERS, metaReducers } from "./root.store";
import { RouterSEOEffects } from "./seo/router.effects";
import { environment } from "../environments/environment";
import { AuthEffects } from "./pages/auth/auth.effects";
import { LayoutEffects } from "./layout/layout.effects";
import { InventoryEffects } from "./pages/tabs/inventory/inventory.effects";

@NgModule({
  imports: [
    NgrxFormsModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    StoreDevtoolsModule.instrument({
      name: "ReusedTire",
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([
      LayoutEffects,
      AuthEffects,
      InventoryEffects,
      RouterSEOEffects,
    ]),
  ],
})
export class RootStoreModule {}
