import { createSelector } from "@ngrx/store";

import * as fromInventory from "./inventory.reducer";
import { selecInventoryState } from "../../../root.store";
import { AugmentedBOOLEAN, AugmentedBOOLEANString } from "../../../libs";

export const selectSearchResultSegmentValue = createSelector(
  selecInventoryState,
  (state: fromInventory.InventoryStoreState) => {
    return state.searchResultSegmentValue;
  }
);

export const selectSearchResultsSet = createSelector(
  selecInventoryState,
  selectSearchResultSegmentValue,
  (state, segmentValue) => {
    return state.tireSearchResults.ids
      .map((entityID) => state.tireSearchResults.entities[entityID])
      .filter((item) => {
        switch (segmentValue) {
          case "NON_VERIFIED": {
            return item.is_verified === AugmentedBOOLEANString.FALSE;
          }

          case "VERIFIED": {
            return item.is_verified === AugmentedBOOLEANString.TRUE;
          }

          default: {
            return true;
          }
        }
      });
  }
);

export const selectLocalInventory = createSelector(
  selecInventoryState,
  selectSearchResultSegmentValue,
  (state, segmentValue) => {
    return state.localInventory.ids
      .map((entityID) => state.localInventory.entities[entityID])
      .filter((item) => {
        switch (segmentValue) {
          case "NON_VERIFIED": {
            return item.is_verified === AugmentedBOOLEAN.FALSE;
          }

          case "VERIFIED": {
            return item.is_verified === AugmentedBOOLEAN.TRUE;
          }

          default: {
            return true;
          }
        }
      });
  }
);

export const selectLastUniqueID = createSelector(
  selecInventoryState,
  (state: fromInventory.InventoryStoreState) => state.lastUniqueID
);

export const selectApiURIBase = createSelector(
  selecInventoryState,
  (state: fromInventory.InventoryStoreState) => state.apiURIBase
);

const selectTireConstantsInternal = createSelector(
  selecInventoryState,
  (state: fromInventory.InventoryStoreState) => state.tireConstants
);

const selectTireBrands = createSelector(
  selectTireConstantsInternal,
  (state: fromInventory.InventoryStoreState["tireConstants"]) =>
    Object.keys(state.tireBrands).map(({}, index) => {
      return state.tireBrands[index];
    })
);

const selectTirePly = createSelector(
  selectTireConstantsInternal,
  (state: fromInventory.InventoryStoreState["tireConstants"]) =>
    Object.keys(state.tirePly).map(({}, index) => {
      return state.tirePly[index];
    })
);

const selectTireSeasonRatings = createSelector(
  selectTireConstantsInternal,
  (state: fromInventory.InventoryStoreState["tireConstants"]) =>
    Object.keys(state.tireSeasonRatings).map(({}, index) => {
      return state.tireSeasonRatings[index];
    })
);

const selectTireSizes = createSelector(
  selectTireConstantsInternal,
  (state: fromInventory.InventoryStoreState["tireConstants"]) =>
    Object.keys(state.tireSizes).map(({}, index) => {
      return state.tireSizes[index];
    })
);

const selectTireSpeedRatings = createSelector(
  selectTireConstantsInternal,
  (state: fromInventory.InventoryStoreState["tireConstants"]) =>
    Object.keys(state.tireSpeedRatings).map(({}, index) => {
      return state.tireSpeedRatings[index];
    })
);

export const selectTireConstants = createSelector(
  selectTireBrands,
  selectTirePly,
  selectTireSeasonRatings,
  selectTireSizes,
  selectTireSpeedRatings,
  (tireBrands, tirePly, tireSeasonRatings, tireSizes, tireSpeedRatings) => {
    return {
      tireBrands,
      tirePly,
      tireSeasonRatings,
      tireSizes,
      tireSpeedRatings,
    };
  }
);
