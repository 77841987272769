import { Component } from "@angular/core";
import { enableMapSet } from "immer";

import { Platform } from "@ionic/angular";

import { Plugins } from "@capacitor/core";
import { AboutDevice } from "./layout/layout.models";
import { select, Store } from "@ngrx/store";
import { Storage } from "@ionic/storage";

import * as fromLayout from "./layout/layout.actions";
import * as fromAuth from "./pages/auth/auth.actions";
import * as fromInventory from "./pages/tabs/inventory/inventory.actions";
import { DATABASE_STORE_ID_LOCATION } from "./pages/auth/auth.models";
import { Router } from "@angular/router";

const { Device } = Plugins;

@Component({
  selector: "app-root",
  template: `<app-split-pane></app-split-pane>`,
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private store: Store,
    private storage: Storage,
    public router: Router
  ) {
    enableMapSet();
    this.store.dispatch(fromAuth.INIT());

    this.onPlatformInit()
      .then((deviceInfo) => {
        this.store.dispatch(fromLayout.DEVICE_INFO_AVAILABLE({ deviceInfo }));
      })
      .catch((error) => {
        this.store.dispatch(
          fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT({
            alertComponentOptions: {
              title: "Unknown error",
              message: `Restarting the application might fix this issue <br /> ${JSON.stringify(
                error
              )}`,
              buttons: {
                confirm: {
                  buttonText: "OK",
                  buttonAction: async () => {
                    /*no-op*/
                  },
                },
                cancel: null,
              },
            },
          })
        );
      });
  }

  async onPlatformInit(): Promise<AboutDevice> {
    const deviceReadySource = await this.platform.ready();
    const storeID: string = await this.storage.get(DATABASE_STORE_ID_LOCATION);

    if (storeID && storeID !== "") {
      this.store.dispatch(
        fromInventory.UPDATE_API_URI_BASE({
          baseURI: `https://store${storeID}.reusedtire.com/`,
        })
      );
    }

    return {
      ...(await Device.getInfo()),
      ...(await Device.getBatteryInfo()),
      isCordova: deviceReadySource === "cordova" ? true : false,
    };
  }
}
