import { createAction, props } from "@ngrx/store";
import {
  OnlineTireInventoryClientSideFormat,
  OnlineTireInventoryServerSideFormat,
} from "../inventory.models";

export const POPULATE_ONLINE_TIRE_INFO = createAction(
  "[TIRE-ONLINE_EDIT] Populate Online data into form",
  props<{ tireInfo: OnlineTireInventoryServerSideFormat }>()
);

export const PATCH_FORM_VALUES = createAction(
  "[TIRE-ONLINE_EDIT] Patch tire online edit form",
  props<{ tireInfoPartial: Partial<OnlineTireInventoryClientSideFormat> }>()
);

export const RESET_TIRE_ONLINE_EDIT_FORM = createAction(
  "[TIRE-ONLINE_EDIT] Reset tire online edit form"
);
