import { Injectable, Inject, SecurityContext } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class CannonicalURLService {
  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private domSanitizer: DomSanitizer
  ) {}

  // `upsertCanonicalURL` will be called by NGRX effects on every route change
  upsertCanonicalURL(url: string) {
    const link =
      this.dom.head.querySelector<HTMLLinkElement>(`link[rel='canonical']`) || // Use existing link tag if it already exists
      this.dom.head.appendChild(this.dom.createElement("link")); // Or, create a new link tag

    // Set canonical url
    link.setAttribute("rel", "canonical");
    link.setAttribute(
      "href",
      this.domSanitizer.sanitize(SecurityContext.URL, url)
    );
  }
}
