import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import * as fromLayout from "./layout.actions";
import { AlertController, ToastController } from "@ionic/angular";
import { switchMap, concatMap, mergeMap } from "rxjs/operators";
import { of, from } from "rxjs";

@Injectable()
export class LayoutEffects {
  presentAlertComponent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromLayout.LAYOUT_PRESENT_ALERT_COMPONENT),
        switchMap(({ alertComponentOptions }) => {
          const cancelButton = alertComponentOptions.buttons.cancel
            ? {
                role: "cancel",
                text: alertComponentOptions.buttons.cancel.buttonText,
                handler: alertComponentOptions.buttons.cancel.buttonAction,
              }
            : null;

          const confirmButton = alertComponentOptions.buttons.confirm
            ? {
                text: alertComponentOptions.buttons.confirm.buttonText,
                handler: alertComponentOptions.buttons.confirm.buttonAction,
              }
            : null;

          return of(
            this.alertController.create({
              header: alertComponentOptions.title,
              message: alertComponentOptions.message,
              buttons: [cancelButton, confirmButton].filter((x) => x !== null),
              backdropDismiss: false,
            })
          );
        }),
        concatMap((alertPromise) =>
          from(alertPromise).pipe(
            switchMap((alertElement) => {
              return from(alertElement.present()).pipe(
                switchMap(() => from(alertElement.onDidDismiss()))
              );
            })
          )
        )
      ),

    {
      dispatch: false,
      useEffectsErrorHandler: true,
    }
  );

  presentAutoDismissingToastComponent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromLayout.LAYOUT_PRESENT_AUTO_DISMISSING_TOAST_COMPONENT),
        switchMap(({ autoDismissingToastComponentOptions }) => {
          return of(
            this.toastController.create(autoDismissingToastComponentOptions)
          );
        }),
        mergeMap((toastPromise) =>
          from(toastPromise).pipe(
            switchMap((toastElement) => {
              return from(toastElement.present());
            })
          )
        )
      ),

    {
      dispatch: false,
      useEffectsErrorHandler: true,
    }
  );

  constructor(
    private actions$: Actions,
    public alertController: AlertController,
    public toastController: ToastController
  ) {}
}
