import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { Title } from "@angular/platform-browser";
import { CannonicalURLService } from "./page-cannonical-url.service";

import { routerNavigationAction } from "@ngrx/router-store";
import { map } from "rxjs/operators";

@Injectable()
export class RouterSEOEffects {
  updateCannonicalURL$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigationAction),
        map(({ payload }) => {
          // `cannonicalURLService` will sanitize URL before upserting
          this.cannonicalURLService.upsertCanonicalURL(
            `https://app.reusedtire.com${payload.event.url}`
          );
        })
      ),

    {
      dispatch: false,
    }
  );

  updateTitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigationAction),
        map(({ payload }) => {
          const urlFragments = String(payload.event.url).split("/");
          let pageName = urlFragments[urlFragments.length - 1];
          if (pageName === "") {
            pageName = "Welcome";
          }
          this.titleService.setTitle(pageName);
        })
      ),

    {
      dispatch: false,
    }
  );

  constructor(
    private cannonicalURLService: CannonicalURLService,
    private titleService: Title,
    private actions$: Actions
  ) {}
}
