export interface SignInForm {
  email: string;
  password: string;
}

export interface IAuthResponse {
  message: "Success" | "Error";
}

export interface IAuthSuccess extends IAuthResponse {
  token: string;
  user_id: number;
}

export type User = Pick<IAuthSuccess, "user_id"> &
  Pick<IAuthSuccess, "token"> & { email: string };

export const DATABASE_AUTH_OBJECT_LOCATION = "auth";
export const DATABASE_STORE_ID_LOCATION = "storeID";
